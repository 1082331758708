import React from "react";
import PropTypes from "prop-types";

const shapes = {
  circle: "rounded-[50%]",
  round: "rounded-[10px]",
};
const variants = {
  fill: {
    white_A700: "bg-white-A700 text-blue_gray-900_01",
    light_green_900: "bg-light_green-900 text-white-A700",
    blue_gray_900: "bg-blue_gray-900 text-white-A700",
  },
  outline: {
    blue_gray_100: "border-blue_gray-100 border border-solid text-black-900",
  },
};
const sizes = {
  xl: "h-[38px] px-[35px] text-base",
  "3xl": "h-[48px] px-4 text-[13px]",
  lg: "h-[35px] px-[35px] text-[13px]",
  "4xl": "h-[78px] px-[26px]",
  md: "h-[32px] px-5 text-xs",
  xs: "h-[26px] px-[31px] text-[9px]",
  sm: "h-[26px] px-[26px] text-base",
  "2xl": "h-[46px] px-[35px] text-base",
};

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant = "fill",
  size = "2xl",
  color = "blue_gray_900",
  ...restProps
}) => {
  return (
    <button
      className={`${className} flex flex-row items-center justify-center text-center cursor-pointer ${(shape && shapes[shape]) || ""} ${(size && sizes[size]) || ""} ${(variant && variants[variant]?.[color]) || ""}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  shape: PropTypes.oneOf(["circle", "round"]),
  size: PropTypes.oneOf(["xl", "3xl", "lg", "4xl", "md", "xs", "sm", "2xl"]),
  variant: PropTypes.oneOf(["fill", "outline"]),
  color: PropTypes.oneOf(["white_A700", "light_green_900", "blue_gray_900", "blue_gray_100","gray_400", "light_green_900"]),
};

export { Button };
