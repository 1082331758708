import React from 'react';

function UploadedFiles({ uploadedFiles }) {
  const handleDownload = (file) => {
    // Construct the URL for downloading the file
    const url = URL.createObjectURL(new Blob([file.fileData.data], { type: 'application/octet-stream' }));
    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', file.fileName);
    // Simulate a click on the anchor element to initiate the download
    document.body.appendChild(link);
    link.click();
    // Clean up by removing the anchor element and revoking the URL object
    link.parentNode.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <div>
      <h2>Uploaded Files</h2>
      <ul>
        {uploadedFiles.map((file, index) => (
          <li key={file._id}>
            <div>
              {/* Attach onClick event to initiate download */}
              <span style={{ cursor: 'pointer' }} onClick={() => handleDownload(file)}>
                File Name: {file.fileName}
              </span>
            </div>
            {/* Add additional information here if needed */}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default UploadedFiles;
