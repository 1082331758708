import React from "react";
import { Button, Text } from "../../components";
import quotableImage from '../../assets/images/Quotable.png';
import galleryImage from '../../assets/images/Gallery.png';
import dividendImage from '../../assets/images/Dividend.png';
import berlinImage from '../../assets/images/Berlin.png';

const images = {
  'Quotable.pptx': quotableImage,
  'Gallery.pptx': galleryImage,
  'Dividend.pptx': dividendImage,
  'Berlin.pptx': berlinImage,
};

export default function LessonAIPagePPTOneCard({
  templatecounter = "Template 1",
  selectTemplate = "Select Template",
  onSelect,
  ...props
}) {
  return (
    <div className="card flex flex-col items-center p-4 bg-white border border-gray-200 rounded-lg shadow-lg" {...props}>
      <img src={images[selectTemplate]} alt={templatecounter} className="w-full h-48 object-cover rounded-t-lg" />
      <div className="flex flex-col items-center justify-center w-full p-4">
        {/* <Text size="3xl" as="p" className="text-lg mb-4">
          {templatecounter}
        </Text> */}
        <Button
          size="sm"
          className="min-w-[116px] rounded font-poppins font-normal"
          onClick={() => onSelect(selectTemplate)}
        >
          {selectTemplate}
        </Button>
      </div>
    </div>
  );
}

