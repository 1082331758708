import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Text } from '../../components';
import { useNavigate, useParams } from "react-router-dom";
import Sidebar4 from '../../components/Sidebar4';
import axios from 'axios';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { GlobalWorkerOptions } from 'pdfjs-dist';
GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.0.279/pdf.worker.min.js`;

export default function LessonAIPageFlowDocTwoPage() {
  const [pdfUrl, setPdfUrl] = useState('');
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const navigate = useNavigate();
  const { id } = useParams(); // Get the ObjectId from the URL

  useEffect(() => {
    const token = localStorage.getItem('token');
    console.log('token:', token);
    const fetchPdfContent = async () => {
      try {
        let response;
        if (id) {
          // Fetch specific instructional design by ID
          response = await axios.get(`http://52.72.29.115:3001/api/get-pdf-content/${id}`,
          { headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`} });
        } else {
          // Fetch the newly generated instructional design
          response = await axios.get('http://52.72.29.115:3001/api/get-pdf-content',
            { headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`} });
        }

        if (!response.data.pdf_content) {
          throw new Error('PDF content not found');
        }

        // Decode base64 string and create a Blob
        const pdfBlob = new Blob([Uint8Array.from(atob(response.data.pdf_content), c => c.charCodeAt(0))], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(pdfBlob);
        setPdfUrl(url);
      } catch (error) {
        console.error('Error fetching PDF content:', error);
      }
    };

    fetchPdfContent();
  }, [id]);

  return (
    <>
      <Helmet>
        <title>LearnMigo</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full items-start justify-between gap-5 bg-white-A700 pr-[303px] md:flex-col md:pr-5">
        <Sidebar4 />
        <div className="mt-[50px] flex w-[50%] flex-col items-center md:w-full">
          <Text size="6xl" as="p" className="relative z-[1]">
            Instructional Design Preview
          </Text>
          {/* Display PDF if URL is available */}
          {pdfUrl && (
            <div style={{ height: '600px', width: '100%' }}>
              <Worker workerUrl={`//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.0.279/pdf.worker.min.js`}>
                <Viewer fileUrl={pdfUrl} plugins={[defaultLayoutPluginInstance]} />
              </Worker>
            </div>
          )}
          <div className="mt-11 flex gap-[15px]">
            <Button
              color="light_green_900"
              shape="round"
              className="w-full !rounded-lg border border-solid border-blue_gray-900_01 font-medium sm:px-5"
              onClick={() => {
                // Create a temporary <a> element to trigger the download
                const tempLink = document.createElement('a');
                tempLink.href = pdfUrl;
                tempLink.setAttribute('download', 'output.pdf');
                document.body.appendChild(tempLink);
                tempLink.click();
                // Clean up the temporary element
                document.body.removeChild(tempLink);
              }}
            >
              Download
            </Button>
            <Button
              color="light_green_900"
              shape="round"
              className="w-full !rounded-lg font-medium sm:px-5"
              onClick={() => navigate("/LessonAIpagepptone")}>
                Next
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
