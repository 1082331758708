import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Text, Button, Img, CheckBox, Input } from "../../components";

export default function SigninPagePage() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    rememberMe: false,
  });

  const navigate = useNavigate();

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!formData.email) newErrors.email = 'Email is required';
    if (!formData.password) newErrors.password = 'Password is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (!name) {
      // Handle the case where 'name' is undefined
      console.error("Input field has no 'name' attribute.");
      return;
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const response = await fetch('https://migolearn.live/api/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (response.ok) {
        // Save the token in local storage
        localStorage.setItem('token', data.token);
        // Redirect to the main page
        navigate('/LessonAIpagemain');
      } else {
        // Handle errors (e.g., display error message)
        setErrors({ server: data.error });
      }
    } catch (error) {
      console.error('Error:', error);
      alert("Invalid Credentials");
      setErrors({ server: 'An unexpected error occurred' });
    }
  };

  return (
    <>
      <Helmet>
        <title>LearnMigo</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full items-center justify-between gap-5 bg-white-A700 pr-[135px] md:flex-col md:pr-5">
        <div className="flex w-[57%] flex-col items-start justify-center gap-[39px] rounded-br-[80px] rounded-tr-[80px] bg-indigo-300_01 px-[27px] pb-[147px] pt-5 md:w-full md:p-5 md:pb-5 sm:px-5">
          <Img src="images/img_header_logo.svg" alt="headerlogoone" className="h-[44px] w-[44px]" />
          <div className="mr-[70px] flex w-[79%] flex-col gap-11 self-end md:w-full">
            <Img src="images/img_student_preparing.png" alt="studentone" className="h-[365px] object-cover" />
            <Img src="images/img_satisfied_graduate.png" alt="satisfiedone" className="h-[365px] object-cover" />
          </div>
        </div>
        <div className="w-[31%] pb-[63px] md:w-full md:pb-5">
          <form className="flex flex-col items-end gap-[23px]" onSubmit={handleSubmit}>
            <div className="flex flex-col self-stretch">
              <div className="flex flex-col items-start gap-0.5">
                <div className="flex pb-1">
                  <Text size="5xl" as="p" className="!font-normal">
                    Welcome back!
                  </Text>
                </div>
                <Text size="3xl" as="p">
                  Enter your Credentials to access your account
                </Text>
              </div>
              <div className="mt-[108px] flex flex-col items-start gap-[23px]">
                <div className="flex flex-col items-start self-stretch pb-1">
                  <Text as="p">Email</Text>
                  <Input
                    color="black_900_66"
                    size="xs"
                    variant="outline"
                    shape="round"
                    type="email"
                    name="email"
                    placeholder={`Enter your email`}
                    value={formData.email}
                    onChange={email => handleInputChange({ target: { value: email, name: 'email' } })}
                    className="font-medium sm:pr-5"
                  />
                  {errors.email && <Text color="red" as="p">{errors.email}</Text>}
                </div>
                <div className="flex flex-col items-start self-stretch pb-1 mt-[19px]">
                  <Text as="p">Password</Text>
                  <Input
                    color="black_900_66"
                    size="xs"
                    variant="outline"
                    shape="round"
                    type="password"
                    name="password"
                    placeholder={`Password`}
                    value={formData.password}
                    onChange={password => handleInputChange({ target: { value: password, name: 'password' } })}
                    className="font-medium sm:pr-5"
                  />
                  {errors.password && <Text color="red" as="p">{errors.password}</Text>}
                </div>
                <CheckBox
                  name="rememberMe"
                  label="Remember for 30 days"
                  id="rememberfor30da"
                  checked={formData.rememberMe}
                  onChange={rememberMe => handleInputChange({ target: { value: rememberMe, name: 'rememberMe' } })}
                  className="gap-1.5 p-px text-left text-[9px] font-medium text-black-900"
                />
                <Button type="submit" color="light_green_900" 
                size="lg" 
                shape="round" 
                className="w-full font-bold sm:px-5">
                  Login
                </Button>
              </div>
              <div className="mt-[59px] flex flex-col items-center">
                <Text
                  size="xs"
                  as="p"
                  className="relative z-[1] flex items-center justify-center bg-white-A700 px-1 py-px"
                >
                  Or
                </Text>
                <div className="relative mt-[-5px] h-[2px] w-full self-stretch bg-gray-100_01" />
              </div>
              <div className="mt-[60px] flex gap-[23px]">
                <Button
                  color="blue_gray_100"
                  size="md"
                  variant="outline"
                  shape="round"
                  leftIcon={
                    <Img src="images/img_icons8google_1.svg" alt="icons8-google 1" className="h-[24px] w-[24px]" />
                  }
                  className="w-full gap-2.5 self-end font-medium"
                >
                  Sign in with Google
                </Button>
                <Button
                  color="blue_gray_100"
                  size="md"
                  variant="outline"
                  shape="round"
                  leftIcon={
                    <Img
                      src="images/img_icons8applelogo_1.svg"
                      alt="icons8-apple-logo 1"
                      className="h-[24px] w-[24px]"
                    />
                  }
                  className="w-full gap-2.5 self-start font-medium sm:px-5"
                >
                  Sign in with Apple
                </Button>
              </div>
            </div>
            <div className="mr-[62px] flex md:mr-0">
              <Text as="p">
                <span className="text-black-900">Don’t have an account? &nbsp;</span>
                <button className="text-indigo_A700" onClick={() => navigate('/signuppage',{ replace: true })}>Sign Up</button>
              </Text>
            </div>
          </form>
          {errors.server && <Text color="red" as="p">{errors.server}</Text>}
        </div>
      </div>
    </>
  );
}
