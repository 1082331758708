
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Text, Img, Button } from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar4 from '../../components/Sidebar4';
import axios from 'axios';

export default function LessonAIPageVideoThreePage() {
  const [videoData, setVideoData] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const navigate = useNavigate();
  const { id } = useParams(); // Get the ObjectId from the URL

  useEffect(() => {
    const token = localStorage.getItem('token');
    console.log('token:', token);
    fetchVideoData();
  }, [id]);

  const fetchVideoData = async () => {
    const token = localStorage.getItem('token');
    console.log('token:', token);
    try {
      let response;
      if (id) {
        response = await axios.get(`https://migolearn.live/api/get-video-content/${id}`,
          { headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`} });
      } else {
        response = await axios.get('https://migolearn.live/api/video_data',
          { headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`} });
      }
      setVideoData(response.data);
    } catch (error) {
      console.error('Error fetching video data:', error);
    }
  };

  const handleThumbnailClick = (videoUrl) => {
    setSelectedVideo(videoUrl);
  };

  return (
    <>
      <Helmet>
        <title>LearnMigo</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="w-full bg-white-A700 pr-[46px] md:pr-5">
        <div className="flex items-start justify-between gap-5">
          <Sidebar4 />
          <div className="flex w-[74%] flex-col items-center gap-[46px]">
            <Text size="6xl" as="p" className="!font-hind">
              Your Videos
            </Text>
            <div className="flex flex-col gap-[30px] self-stretch">
              <div>
                <div className="flex flex-col gap-3">
                  <div className="flex flex-col items-center gap-7 md:flex-col">
                    <div className="w-full h-[500px] border border-solid border-black-900 shadow-xs md:self-stretch md:p-5">
                      {selectedVideo && (
                        <video key={selectedVideo} controls style={{ width: "100%", height: "100%" }}>
                          <source src={selectedVideo} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      )}
                    </div>
                    <div className="flex justify-center gap-5 mt-5 w-full">
                      <Button shape="round" className="!rounded-lg" onClick={() => window.location.href = selectedVideo}>
                        Download
                      </Button>
                      <Button shape="round" color="light_green_900" className="!rounded-lg" onClick={() => navigate("/LessonAIpagecompletion")}>
                        Next
                      </Button>
                    </div>
                  </div>
                  <div className="grid grid-cols-3 justify-center gap-[50px] md:grid-cols-2 sm:grid-cols-1 mt-10">
                    {videoData.map((video, index) => (
                      <div
                        key={"gridvideo" + index}
                        className="relative h-[148px] w-full border border-solid border-black-900 shadow-xs md:h-auto"
                        onClick={() => handleThumbnailClick(video.videoUrl)}
                      >
                        <Img src={video.thumbnailUrl} alt="image" className="h-[148px] w-full object-contain" />
                        <Text as="p" className="text-shadow-ts absolute bottom-[12.00px] left-0 right-0 m-auto w-max">
                          {`Video ${index + 1}`}
                        </Text>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
