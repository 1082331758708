import React,{useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Button, Text, Img } from "../../components";
import Sidebar5 from "../../components/Sidebar5";

export default function MainDashboardPage() {

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/profaiupdatepage"); 
  };

  const [avatars, setAvatars] = useState([]);
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const fetchAvatars = async () => {
  //     try {
  //       const response = await fetch('http://52.72.29.115:3001/api/avatars');
  //       if (response.ok) {
  //         const data = await response.json();
  //         setAvatars(data);
  //         setLoading(false);
  //       } else {
  //         console.error('Failed to fetch avatar data');
  //       }
  //     } catch (error) {
  //       console.error('Error:', error);
  //       setLoading(false);
  //     }
  //   };

  //   fetchAvatars();
  // }, []);

  useEffect(() => {
    // Fetch the latest avatar data from the backend API
    const fetchLatestAvatar = async () => {
      try {
        const response = await fetch('http://52.72.29.115:3001/api/latest-avatar');
        if (response.ok) {
          const data = await response.json();
          setAvatars(data);
          setLoading(false);
          console.log('Fetched avatar data:', data);
        } else {
          console.error('Failed to fetch latest avatar data');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };
  
    fetchLatestAvatar();
  }, []);
  


  return (
    <>
      <Helmet>
        <title>LearnMigo</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full items-start justify-between gap-5 bg-white-A700 pr-[156px] md:flex-col md:pr-5">
        <Sidebar5 className="!sticky top-0 flex h-screen w-[312px] flex-col gap-[17px] overflow-auto bg-gray-100 pt-[5px] md:hidden md:p-5" />
        <div className="mt-[41px] flex w-[71%] flex-col items-start gap-9 md:w-full">
          <Text size="6xl" as="p">
            Main Dashboard
          </Text>
          <div className="flex justify-between gap-5 self-stretch md:flex-col">
            <div className="flex h-[195px] w-[47%] items-center rounded-[7px] bg-[url(/public/images/img_card.svg)] bg-cover bg-no-repeat px-[34px] pb-5 pt-[23px] md:h-auto md:w-full md:p-5 sm:px-5 sm:pt-5">
              <div className="flex w-[60%] flex-col items-start md:w-full">
                <Text size="3xl" as="p" className="!text-[16.5px]">
                  Your LessonAI Lessons
                </Text>
                <Text
                  size="s"
                  as="p"
                  className="mt-2 w-full !font-dmsans !text-[10.5px] leading-[15px] !text-black-900_66"
                >
                  <>
                    Lessons Created : #1 Lesson1Name
                    <br /> #2 Lesson2Name
                    <br /> #3 Lesson3Name
                  </>
                </Text>
                <a href="https://www.youtube.com/embed/bv8Fxk0sz7I" target="_blank">
                  <Button size="xs" className="mt-[46px] min-w-[145px] rounded-[4.5px] font-normal sm:px-5">
                    View Your Lessons
                  </Button>
                </a>
              </div>
            </div>
          <div className="flex h-[195px] w-[47%] items-center justify-center rounded-[7px] bg-[url(/public/images/img_card.svg)] bg-cover bg-no-repeat px-[23px] pb-5 pt-[23px] md:h-auto md:w-full md:p-5 sm:px-5 sm:pt-5">
            {loading ? (
              <div>Loading...</div>
            ) : (
              <div className="flex w-full flex-col items-start gap-[19px]">
                <Text size="3xl" as="p" className="!text-[16.5px]">
                  Your Created Avatar
                </Text>
                <div className="self-stretch">
                {/* {Array.isArray(avatars) && avatars.map((data) => ( */}
                  <div className="flex flex-col gap-[15px]">
                    <div className="flex w-[84%] items-start gap-[26px] md:w-full">
                      <div className="flex w-[22%] justify-center rounded-[30px] border border-solid border-blue_gray-100">
                      <Img src={avatars.profile_picture} alt="Profile Picture" className="h-[58px] w-full rounded-[50%]" />
                      </div>
                      <Text
                        size="s"
                        as="p"
                        className="mt-2 w-full !font-dmsans !text-[10.5px] leading-[15px] !text-black-900_66"
                      >
                        <>
                          Name: {avatars.name}
                          <br />
                          Description: {avatars.description}
                          <br />
                          Greeting: {avatars.greeting}
                        </>
                      </Text>
                    </div>
                    <div className="flex justify-between gap-5">
                      <a href="https://www.youtube.com/embed/bv8Fxk0sz7I" target="_blank">
                        <Button
                          size="xs"
                          rightIcon={<Img src="images/img_send.svg" alt="send" className="h-[11px] w-[10px]" />}
                          className="min-w-[86px] gap-1 rounded-[4.5px] font-normal sm:px-5"
                        >
                          Share{" "}
                        </Button>
                      </a>
                      <div className="flex gap-5">
                          <Button size="xs" className="min-w-[86px] rounded font-normal sm:px-5" onClick={handleButtonClick}>
                            Update
                          </Button>
                        <Button size="xs" className="min-w-[86px] rounded-[4.5px] font-normal">
                          Add Materials
                        </Button>
                      </div>
                    </div>
                  </div>
                   {/* ))} */}
                </div>
              </div>
            )}
           </div>
          </div>
          <div className="flex h-[195px] w-[47%] items-center justify-center rounded-[7px] bg-[url(/public/images/img_card.svg)] bg-cover bg-no-repeat px-[23px] pb-[61px] pt-[23px] md:h-auto md:w-full md:pb-5 sm:p-5">
            <div className="flex w-[85%] justify-center md:w-full">
              <div className="flex w-full flex-col items-start">
                <Text size="3xl" as="p" className="!text-[16.5px]">
                  Your Materials
                </Text>
                <Text size="s" as="p" className="mt-2 !font-dmsans !text-[10.5px] !text-black-900_66">
                  Subject : Science Topics Added : Physics, Chemistry{" "}
                </Text>
                <div className="mt-9 flex justify-between gap-5 self-stretch">
                  <a href="https://www.youtube.com/embed/bv8Fxk0sz7I" target="_blank">
                    <Button size="xs" className="min-w-[145px] rounded font-normal sm:px-5">
                      View Materials
                    </Button>
                  </a>
                  <a href="https://www.youtube.com/embed/bv8Fxk0sz7I" target="_blank">
                    <Button size="xs" className="min-w-[145px] rounded-[4.5px] font-normal sm:px-5">
                      Update Materials
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
