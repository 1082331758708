import React from "react";
import { Helmet } from "react-helmet";
import { Button, Text, Img } from "../../components";
import Sidebar4 from "../../components/Sidebar4";
import { useNavigate } from "react-router-dom";
// import withAuth from '../../pages/AuthCheck/authcheck';

export default function LessonAIPageMainPage() {

  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>LearnMigo</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full items-start justify-between gap-5 bg-white-A700 pr-[305px] md:flex-col md:pr-5">
        <Sidebar4 />
        <div className="mt-3 flex w-[66%] flex-col items-start md:w-full">
          <div className="flex gap-[45px]">
          <Text size="6xl" as="p">
            Your ClassCopilot
          </Text>
          <Button color="light_green_900" shape="round" 
              className="min-w-[207px] mt-[5px] !rounded-lg font-medium sm:px-5" 
              onClick={() => navigate("/LessonAIpagealllessons")}>
                View Lessons
          </Button>
          </div>
          <Text
            size="3xl"
            as="p"
            className="ml-[7px] !font-dmsans !text-[16.5px] !font-normal !text-black-900_66 md:ml-0"
          >
            Complete the Flow Below to Create a Lesson
          </Text>
          <div className="mt-[50px] flex w-[86%] items-center justify-between gap-5 self-end md:w-full md:p-5 sm:flex-col">
            <div className="relative h-[576px] w-[8%] md:h-auto sm:w-full">
              <div className="m-auto h-[515px] w-[5px] bg-teal-500" />
              <div className="absolute bottom-0 left-0 right-0 top-0 m-auto flex h-max w-full flex-col items-center gap-[127px] md:gap-[95px] sm:gap-[63px]">
                <Text
                  size="4xl"
                  as="p"
                  className="flex h-[52px] w-[52px] items-center justify-center rounded-[26px] bg-teal-500 text-center !text-[30.87px] !text-white-A700"
                >
                  1
                </Text>
                <div className=" h-[52px] w-[52px] justify-center flex rotate-[90deg] flex-col items-center rounded-[26px] bg-teal-500 px-3.5 pb-px">
                  <Text size="4xl" as="p" className="rotate-[-90deg] !text-[30.87px] !text-white-A700">
                    2
                  </Text>
                </div>
                <div className=" h-[52px] w-[52px] justify-center flex rotate-[90deg] flex-col items-center rounded-[26px] bg-teal-500 px-3.5 pb-px">
                  <Text size="4xl" as="p" className="rotate-[-90deg] !text-[30.87px] !text-white-A700">
                    3
                  </Text>
                </div>
                <Text
                  size="4xl"
                  as="p"
                  className="mb-10 flex h-[47px] w-[47px] rotate-[0deg] items-center justify-center rounded-[26px] bg-teal-500 text-center !text-[30.87px] !text-white-A700"
                >
                  4
                </Text>
              </div>
            </div>
            <div className="flex w-[80%] flex-col gap-[54px] sm:w-full">
              <div className="relative h-[121px] flex-1 md:h-auto md:w-full md:flex-none">
                <Img src="images/img_card.svg" alt="image" className="h-[117px] w-full rounded-[7px]" />
                <div className="absolute bottom-0 left-0 right-0 top-0 m-auto flex h-max w-full flex-col items-start rounded-[7px] bg-gray-100 px-10 pb-[17px] pt-[15px] sm:px-5">
                  <Text size="3xl" as="p" className="!text-[16.5px]">
                    Create Learning Outcomes 
                  </Text>
                  <Text size="s" as="p" className="!font-dmsans !text-[10.5px] !text-black-900_66">
                    Creates learning outcomes using Bloom&#39;s Taxonomy
                  </Text>
                    <Button size="sm" className="mt-[22px] min-w-[142px] rounded font-poppins font-normal sm:px-5" 
                    onClick={() => navigate("/LessonAIpageloone")}>
                      Get Started
                    </Button>
                </div>
              </div>
              <div className="flex h-[121px] flex-1 items-center rounded-[7px] bg-[url(/public/images/img_card.svg)] bg-cover bg-no-repeat md:h-auto">
                <div className="flex h-[121px] w-full items-center rounded-[7px] bg-[url(/public/images/img_card.svg)] bg-cover bg-no-repeat px-10 pb-[13px] pt-4 md:h-auto sm:px-5">
                  <div className="flex w-[64%] flex-col items-start md:w-full">
                    <Text size="3xl" as="p" className="!text-[16.5px]">
                      Create Instructional Design
                    </Text>
                    <Text
                      size="s"
                      as="p"
                      className="w-full !font-dmsans !text-[10.5px] leading-[15px] !text-black-900_66"
                    >
                      Creates instructional Design Document of the learning outcomes based on Gagne&#39;s Principles
                    </Text>
                      <div className="mt-[9px] flex">
                        <Button size="sm" className="min-w-[143px] rounded font-poppins font-normal sm:px-5" 
                        onClick={() => navigate("/LessonAIpageflowdocone")}>
                          Get Started
                        </Button>
                      </div>
                  </div>
                </div>
              </div>
              <div className="flex h-[121px] flex-1 items-center rounded-[7px] bg-[url(/public/images/img_card.svg)] bg-cover bg-no-repeat md:h-auto">
                <div className="flex h-[121px] w-full items-center rounded-[7px] bg-[url(/public/images/img_card.svg)] bg-cover bg-no-repeat px-[41px] pb-[9px] pt-3.5 md:h-auto md:px-5">
                  <div className="flex w-[71%] flex-col items-start md:w-full">
                    <Text size="3xl" as="p" className="!text-[16.5px]">
                      Create Presentation
                    </Text>
                    <Text
                      size="s"
                      as="p"
                      className="w-full !font-dmsans !text-[10.5px] leading-[15px] !text-black-900_66"
                    >
                      Creates classroom materials such as presentations, animations, code demonstrations etc.
                    </Text>
                      <Button size="sm" className="mt-[23px] min-w-[142px] rounded font-medium" onClick={() => navigate("/LessonAIpagepptone")}>
                        Get Started
                      </Button>
                  </div>
                </div>
              </div>
              <div className="flex h-[121px] flex-1 items-center rounded-[7px] bg-[url(/public/images/img_card.svg)] bg-cover bg-no-repeat md:h-auto">
                <div className="flex h-[121px] w-full items-center rounded-[7px] bg-[url(/public/images/img_card.svg)] bg-cover bg-no-repeat px-[41px] pb-[9px] pt-3.5 md:h-auto md:px-5">
                  <div className="flex w-[75%] flex-col items-start md:w-full">
                    <Text size="3xl" as="p" className="!text-[16.5px]">
                      Create Video
                    </Text>
                    <Text
                      size="s"
                      as="p"
                      className="w-full !font-dmsans !text-[10.5px] leading-[15px] !text-black-900_66"
                    >
                      Creates an end-to-end video which can be uploaded on your LMS based upon classroom materials
                    </Text>
                      <Button size="sm" className="mt-[23px] min-w-[142px] rounded font-poppins font-normal sm:px-5" 
                      onClick={() => navigate("/LessonAIpagevideoone")}>
                        Get Started
                      </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// export default withAuth(LessonAIPageMainPage);
