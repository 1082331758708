import { Text, Img } from "./..";
import React from "react";

const basePath = process.env.PUBLIC_URL || "";

export default function LessonAIPageLOThreeSublearning({
  sublearningoutcome,
  outcome,
  examplesdescription,
  modeofdelivery,
  timerequired,
  ...props
}) {
  console.log('Sub-Learning Outcome Props:', { sublearningoutcome, outcome, examplesdescription, modeofdelivery, timerequired });

  return (
    <div
      {...props}
      className={`${props.className} flex flex-col items-start w-full mt-2.5 gap-0.5 p-[9px] border-blue_gray-100_02 border border-solid bg-gray-100_04 shadow-xs rounded-[20px]`}
    >
      <p size="6xl" as="p" className="ml-[17px] text-[22px] font-medium !font-hind !text-black-900_02 md:ml-0">
        {sublearningoutcome}
      </p>
      <div className="flex mb-[20px] ml-[23px] items-start self-stretch md:ml-0">
        <Img src={`${basePath}/images/img_small_arrow.svg`} alt="smallarrow" className="h-[252px] w-[3%] md:w-full" />
        <div className="flex flex-1 flex-col items-start gap-[17px] md:self-stretch">
          <Text
            size="3xl"
            as="p"
            className="text-shadow-ts rounded-[20px] border border-solid border-blue_gray-100_02 bg-gray-100_04 py-2.5 pl-[22px] pr-[35px] !font-hind !text-black-900_02 sm:px-5 shadow-xs"
          >
            <span className="text-black-900_02">Outcome :&nbsp;</span>
            <span className="font-normal text-black-900_02">{outcome}</span>
          </Text>
          <div className="flex self-stretch rounded-[20px] border border-solid border-blue_gray-100_02 bg-gray-100_04 p-2 shadow-xs">
            <Text size="3xl" as="p" className="mb-2.5 w-[97%] !font-hind leading-7 !text-black-900_02">
              <span className="text-black-900_02">Examples :&nbsp;</span>
              <span className="font-normal text-black-900_02">{examplesdescription}</span>
            </Text>
          </div>
          <Text
            size="3xl"
            as="p"
            className="text-shadow-ts shadow-xs rounded-[20px] border border-solid border-blue_gray-100_02 bg-gray-100_04 py-2.5 pl-[15px] pr-[35px] !font-hind !text-black-900_02 sm:pr-5"
          >
            <span className="text-black-900_02">Mode of Delivery :&nbsp;</span>
            <span className="font-normal text-black-900_02">{modeofdelivery}</span>
          </Text>
          <Text
            size="3xl"
            as="p"
            className="text-shadow-ts rounded-[20px] border border-solid border-blue_gray-100_02 bg-gray-100_04 py-2.5 pl-[15px] pr-[35px] !font-hind !text-black-900_02 sm:pr-5 shadow-xs"
          >
            <span className="text-black-900_02">Time Required :&nbsp;</span>
            <span className="font-normal text-black-900_02">{timerequired}</span>
          </Text>
        </div>
      </div>
    </div>
  );
}
