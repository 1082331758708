import React from "react";
import { Helmet } from "react-helmet";
import { Button, Input, Text } from "../../components";
import Sidebar1 from "../../components/Sidebar1";

export default function MaterialsUploadPagePage() {
  return (
    <>
      <Helmet>
        <title>LearnMigo</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full items-start justify-between gap-5 bg-white-A700 pr-[364px] md:flex-col md:pr-5">
        <Sidebar1 />
        <div className="mt-[120px] w-[40%] md:w-full">
          <div className="flex flex-col items-center">
            <Text size="6xl" as="p" className="relative z-[1]">
              Upload your Materials
            </Text>
            <Text as="p" className="relative mt-[-8px] w-full leading-7 !text-gray-500">
              You can upload upto 10 Materials and the file size should not exceed 100MB per file.
            </Text>
          </div>
          <div className="mt-2.5 flex flex-col items-start gap-1.5">
            <Text as="p" className="!text-blue_gray-900_01">
              Upload Here
            </Text>
            <Input
              type="file"
              accept="*"
              name="inputoneone"
              placeholder={`You can upload upto 10 materials here...`}
              className="h-[46px] rounded-lg border-gray-400 bg-white-A700 pl-4 pr-[35px] font-hind text-sm text-gray-500 sm:pr-5"
            />
          </div>
          <a href="https://www.youtube.com/embed/bv8Fxk0sz7I" target="_blank">
            <div className="mt-[29px] flex gap-[15px]">
              <Button
                color="white_A700"
                shape="round"
                className="w-full !rounded-lg border border-solid border-blue_gray-900_01 font-medium sm:px-5"
              >
                Cancel
              </Button>
            </div>
          </a>
        </div>
      </div>
    </>
  );
}
