import React from "react";
import { Link } from "react-router-dom";
const Home = () => {
  return (
    <div style={{ height: "100vh", padding: "20px", backgroundColor: "#232323", color: "white" }}>
      <h1 style={{ fontSize: "48px", marginBottom: "20px" }}>Homepage</h1>
      <p style={{ fontSize: "18px", marginBottom: "30px" }}>
        This project was generated By{" "}
        <a href="https://www.dhiwise.com" style={{ color: "#87CEFA", textDecoration: "none" }}>
          Dhiwise
        </a>
        . Quickly use below links to navigate through all pages.
      </p>
      <ul style={{ listStyle: "none", padding: "0" }}>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/signuppage" style={{ color: "#87CEFA", textDecoration: "none" }}>
            SignUpPage
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/signinpage" style={{ color: "#87CEFA", textDecoration: "none" }}>
            SigninPage
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/maindashboard" style={{ color: "#87CEFA", textDecoration: "none" }}>
            MainDashboard
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/profaidashboardpageone" style={{ color: "#87CEFA", textDecoration: "none" }}>
            ProfAIDashboardPageOne
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/profaipage" style={{ color: "#87CEFA", textDecoration: "none" }}>
            ProfAIPage
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/profaiupdatepage" style={{ color: "#87CEFA", textDecoration: "none" }}>
            ProfAIUpdatePage
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/maindashboardone" style={{ color: "#87CEFA", textDecoration: "none" }}>
            MainDashboardOne
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/LessonAIpagemain" style={{ color: "#87CEFA", textDecoration: "none" }}>
            LessonAIPageMain
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/LessonAIpageloone" style={{ color: "#87CEFA", textDecoration: "none" }}>
            LessonAIPageLOOne
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/LessonAIpagelo15" style={{ color: "#87CEFA", textDecoration: "none" }}>
            LessonAIPageLO15
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/LessonAIpagelotwo" style={{ color: "#87CEFA", textDecoration: "none" }}>
            LessonAIPageLOTwo
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/LessonAIpagelothree" style={{ color: "#87CEFA", textDecoration: "none" }}>
            LessonAIPageLOThree
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/LessonAIpagelothreenew" style={{ color: "#87CEFA", textDecoration: "none" }}>
            LessonAIPageLOThreeNew
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/LessonAIpageflowdocone" style={{ color: "#87CEFA", textDecoration: "none" }}>
            LessonAIPageFlowDocOne
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/LessonAIpageflowdoctwo" style={{ color: "#87CEFA", textDecoration: "none" }}>
            LessonAIPageFlowDocTwo
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/LessonAIpagepptone" style={{ color: "#87CEFA", textDecoration: "none" }}>
            LessonAIPagePPTOne
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/LessonAIpageppttwo" style={{ color: "#87CEFA", textDecoration: "none" }}>
            LessonAIPagePPTTwo
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/LessonAIpagepptthree" style={{ color: "#87CEFA", textDecoration: "none" }}>
            LessonAIPagePPTThree
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/LessonAIpagevideoone" style={{ color: "#87CEFA", textDecoration: "none" }}>
            LessonAIPageVideoOne
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/LessonAIpagevideotwo" style={{ color: "#87CEFA", textDecoration: "none" }}>
            LessonAIPageVideoTwo
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/LessonAIpagecompletion" style={{ color: "#87CEFA", textDecoration: "none" }}>
            LessonAIPageCompletion
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/LessonAIpagealllessons" style={{ color: "#87CEFA", textDecoration: "none" }}>
            LessonAIPageAllLessons
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/LessonAIpagelothree/:id?" style={{ color: "#87CEFA", textDecoration: "none" }}>
          LessonAIPageLOThree with id
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/materialspagemain" style={{ color: "#87CEFA", textDecoration: "none" }}>
            MaterialsPageMain
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/materialsuploadpage" style={{ color: "#87CEFA", textDecoration: "none" }}>
            MaterialsUploadPage
          </Link>
        </li>
      </ul>
    </div>
  );
};
export default Home;
