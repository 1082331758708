import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Button, SelectBox, Img, Text, Input } from "../../components";
import Sidebar4 from "../../components/Sidebar4";
import CircularLoadingBar from '../../components/CircularLoadBar/CircularLoadBar'; 

const dropDownOptions = [
  { label: "MCQ", value: "MCQ" },
  { label: "Multiple Option Correct", value: "Multiple Option Correct" },
  { label: "Open Ended", value: "Open Ended" },
];

export default function LessonAIPageFlowDocOnePage() {
  

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [formData, setFormData] = useState({
      no_of_assessments: '',
      type_of_assessments: [],
      learning_outcomes: ''
    });

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      if (!name) {
        // Handle the case where 'name' is undefined
        console.error("Input field has no 'name' attribute.");
        return;
      }
      setFormData({ ...formData, [name]: value });
    };
  
    const handleSelectChange = (name, value) => {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: Array.isArray(value) ? value : value || ''
      }));
    };
  
    const handleFormSubmit = async (e) => {
      e.preventDefault();
      await handleSubmit();
    };

    const handleSubmit = async () => {

      const token = localStorage.getItem('token');
      console.log('token:', token);

      const restructuredData = {
        no_of_assessments: formData.no_of_assessments,
        type_of_assessments: formData.type_of_assessments.value,
      }

      console.log("Entering handleSubmit with this data :", restructuredData);

      setIsLoading(true);
      setError(false);

      const response = await fetch("http://52.72.29.115:3001/api/instructional_design", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      body: JSON.stringify(restructuredData)
    });

    if (response.ok) {
      const data = await response.json();
      console.log('API response:', data);
      setIsLoading(false); // Hide loading bar
      navigate("/LessonAIpageflowdoctwo")
    }
    else {
      console.error('API request failed');
      setError(true);
    }
  }

  const handleRetry = () => {
    setError(false);
    handleSubmit();
  };

                             
  return (
    <>
      <Helmet>
        <title>LearnMigo</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full items-start justify-between gap-5 bg-white-A700 pr-[346px] md:flex-col md:pr-5">
        <Sidebar4 />
        <div className="mt-[120px] flex w-[44%] flex-col items-center md:w-full">
          <Text size="6xl" as="p" className="!text-[38px]">
            Create Instructional Design
          </Text>
          <div className="flex w-[90%] flex-col gap-[19px] md:w-full md:p-5">
            <Text as="p" className="!text-[13px] leading-7 !text-gray-500">
              Creates instructional Design Document of the learning outcomes based on Gagne&#39;s Principles
            </Text>
            <form onSubmit={handleFormSubmit} id="InstD-form" className="flex flex-col">
              <div className="flex flex-col items-start gap-[7px]">
                <Text as="p" className="!text-blue_gray-900_01">
                  Number of Assessments
                </Text>
                <Input
                  shape="round"
                  type="number"
                  name="no_of_assessments"
                  placeholder={`Enter the Number of Assessments...`}
                  className="!rounded-lg border-gray-400 font-hind sm:pr-5"
                  value={formData.no_of_assessments}
                  onChange={no_of_assessments => handleInputChange({ target: { value: no_of_assessments, name: 'no_of_assessments' } })}
                />
              </div>
              <div className="ml-[11px] mt-2.5 flex items-start gap-1.5 md:ml-0">
                <Img
                  src="images/img_outline_interface_attention.svg"
                  alt="outlinethree"
                  className="h-[16px] w-[16px]"
                />
                <Text as="p" className="!text-gray-500">
                  Click here for Help
                </Text>
              </div>
              <div className="mt-[17px] flex flex-col items-start gap-1.5">
                <Text as="p" className="!text-blue_gray-900_01">
                  Types of Assessments
                </Text>
                <SelectBox
                  shape="round"
                  indicator={<Img src="images/img_arrowdown.svg" alt="arrow_down" className="h-[20px] w-[20px]" />}
                  name="type_of_assessments"
                  placeholder={`MCQ, Multiple Option, Open Ended...`}
                  options={dropDownOptions}
                  className="gap-px self-stretch border border-solid border-gray-400 font-hind sm:pr-5"
                  value={formData.type_of_assessments}
                  onChange={(type_of_assessments) => handleSelectChange('type_of_assessments', type_of_assessments)}
                />
              </div>
              <div className="mt-11 flex gap-[15px]">
                <Button
                  color="white_A700"
                  shape="round"
                  className="w-full !rounded-lg border border-solid border-blue_gray-900_01 font-medium sm:px-5"
                >
                  Cancel
                </Button>
                  <Button type="submit"
                  form="InstD-form"
                  color="light_green_900" 
                  shape="round" 
                  className="w-full !rounded-lg font-medium sm:px-5">
                    Submit
                  </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <CircularLoadingBar
        isLoading={isLoading}
        error={error}
        comment="Generating your Instructional Doc, please wait..."
        onRetry={handleRetry} /> 
    </>
  );
}
