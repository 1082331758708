import {React,useState,useEffect} from "react";
import { Helmet } from "react-helmet";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { Text } from "../../components";
import Sidebar4 from "../../components/Sidebar4";
import CircularLoadingBar from '../../components/CircularLoadBar/CircularLoadBar'; 

export default function LessonAIPageVideoOnePage() {

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);


  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    console.log('token:', token);
    GenerateVideo(); 
    setIsLoading(true);
    setError(false);
  }, []);


  const GenerateVideo = async () => {
    const token = localStorage.getItem('token');
    console.log('token:', token);
    try {
      const response = await axios.post(
        'http://52.72.29.115:3001/api/generate_video',
        {},
        {
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json' 
          }
        }
      );
      if (response.status === 200) { 
        setError(false);
        console.log('Response:', response.data);
        navigate("/LessonAIpagevideotwo");
      } else {
        setError(true);
        console.error('Error:', response.status);
      }
    } catch (error) {
      setError(true);
      console.error('Error generating video:', error);
    }
  };

  const handleRetry = () => {
    setError(false);
    GenerateVideo();
  };

  return (
    <>
      <Helmet>
        <title>LearnMigo</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full items-start justify-between gap-5 bg-white-A700 pr-[376px] md:flex-col md:pr-5">
        <Sidebar4 />
        {/* <div className="mt-[283px] flex w-[37%] flex-col items-center gap-[51px] md:w-full sm:gap-[25px]"> */}
          {/* <div className="w-[23%] rounded-[5px] border border-solid border-blue_gray-100 p-5 md:w-full"> */}
          <CircularLoadingBar
            isLoading={isLoading}
            error={error}
            comment="Generating your Videos, please wait..."
            onRetry={handleRetry} /> 
          </div>
          {/* <Text size="6xl" as="p" className="!text-[38px]">
            Generating The Video
          </Text> */}
        {/* </div> */}
      {/* </div> */}
    </>
  );
}
