import {React,useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Text,Button } from "../../components";
import Sidebar4 from "../../components/Sidebar4";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export default function LessonAIPageLO15Page() {

  const token = localStorage.getItem('token');
  console.log('token:', token);

  const [learningOutcomes, setLearningOutcomes] = useState([]);

  useEffect(() => {
    const fetchLearningOutcomes = async () => {
      try {
        const response = await fetch('http://52.72.29.115:3001/api/learning-outcomes', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}` 
          }
        });
  
        if (response.ok) {
          const data = await response.json();
          setLearningOutcomes(data);
        } else {
          console.error('Failed to fetch learning outcomes');
        }
      } catch (error) {
        console.error('Error fetching learning outcomes:', error);
      }
    };
  
    fetchLearningOutcomes();
  }, []);
  

  console.log('API Assessment:', learningOutcomes.api_assessment_response);

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/LessonAIpagelotwo"); 
  };

  // Function to convert the API response JSON format into normal text
const parseApiResponse = (apiResponse) => {
  if (!apiResponse) return ' ';
  // Remove the enclosing double quotes
  const responseWithoutQuotes = apiResponse.replace(/^"(.*)"$/, '$1');
  // Replace \n with <br /> and remove backslashes
  const formattedResponse = responseWithoutQuotes
    .replace(/\\n/g, '<br />')
    .replace(/\\/g, '');
  // Split the response into lines
  const lines = formattedResponse.split('\n');
  // Replace each line with a bullet point and line break
  const bulletPoints = lines.map(line => {
    // Check if the line already starts with a bullet point
    if (line.startsWith('- ')) {
      return `${line}<br />`;
    } else {
      return `- ${line}<br />`;
    }
  });
  // Render the bullet points as HTML
  return <div dangerouslySetInnerHTML={{ __html: bulletPoints.join('') }} />;
};

  return (
    <>
      <Helmet>
        <title>LearnMigo</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full items-start justify-between gap-5 bg-white-A700 pr-[323px] md:flex-col md:pr-5">
        <Sidebar4 />
        <div className="mt-[30px] flex w-[50%] flex-col items-center gap-[141px] md:w-full md:gap-[105px] sm:gap-[70px]">
          <Text size="6xl" as="p" className="!text-[37px]">
            Based On The Assessment :
          </Text>
            {/* <div className="h-[100px] w-[100px] rounded-[5px] border border-solid border-blue_gray-100 p-5 md:w-full">
              <CircularProgressbar
                strokeWidth={4}
                value={4}
                counterClockwise
                styles={{ trail: {}, path: { strokeLinecap: "square" } }}
                className="h-[48px] w-[48px] border-4 border-solid border-blue_gray-100"
              />
            </div> */}
            {/* <div className="relative mt-[-100px] h-[720px] w-[800px] border border-solid border-black-900">
            {learningOutcomes && learningOutcomes.api_response && parseApiResponse(learningOutcomes.api_response)}
            </div> */}
            <div className="bg-white shadow-lg rounded-lg p-6 mt-[-8rem] w-full max-w-4xl mx-auto">
              <div className="text-lg leading-relaxed whitespace-pre-line"></div>
              {learningOutcomes && learningOutcomes.api_assessment_response && parseApiResponse(learningOutcomes.api_assessment_response)}
            </div>
            <Button
              color="light_green_900"
              shape="round"
              className="w-full !rounded-lg mt-[-100px] w-[300px] border border-solid border-blue_gray-900_01 font-medium sm:px-5"
              onClick={handleButtonClick}
            >
              Next
            </Button>
        </div>
      </div>
    </>
  );
}