import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Button, SelectBox, Img, Text, Input } from "../../components";
import Sidebar4 from "../../components/Sidebar4";
import CircularLoadingBar from '../../components/CircularLoadBar/CircularLoadBar'; 
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

// const dropDownOptions1 = [
//   { label: "K12", value: "K12" },
//   { label: "TestPrep", value: "TestPrep" },
//   { label: "UnderGrad", value: "UnderGrad" },
//   { label: "UpSkiller", value: "UpSkiller" },
// ];

const dropDownOptions1 = [
  { label: "K12", value: "K12" },
  { label: "Test Preparation", value: "Test Preparation" },
  { label: "College Student", value: "College Student" },
  { label: "Working Professional", value: "Working Professional" },
];

// const dropDownOptions2 = [
//   { label: "Undergrad", value: "Undergrad" }, 
//   { label: "Postgrad", value: "Postgrad" },
//   { label: "High School", value: "High School" },
// ];

const dropDownOptions2 = [
  { label: "10-12 Std", value: "10-12 Std" }, 
  { label: "1-10 Std", value: "1-10 Std" },
  { label: "Competitive Exams", value: "Competitive Exams" },
  { label: "Engineering,Medical,Design", value: "Engineering,Medical,Design" },
  { label: "Product,Sales,Engineering,Technical,Marketing,Operations", value: "Product,Sales,Engineering,Technical,Marketing,Operations" },
];

const dropDownOptions3 = [
  { label: "Create", value: "Create" },
  { label: "Evaluate", value: "Evaluate" },
  { label: "Analyze", value: "Analyze" },
  { label: "Apply", value: "Apply" },
  { label: "Understand", value: "Understand" },
  { label: "Remember", value: "Remember" },
];

export default function LessonAIPageLOOnePage() {

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
  topic: '',
  time: '',
  target_type: [],
  target_subtype: [],
  taxonomy_level: []

  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (!name) {
      // Handle the case where 'name' is undefined
      console.error("Input field has no 'name' attribute.");
      return;
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (name, value) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: Array.isArray(value) ? value : value || ''
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    await handleSubmit();
  };

  const handleSubmit = async () => {

    const token = localStorage.getItem('token');
    console.log('token:', token);

    const restructuredData = {
      topic: formData.topic,
      time: formData.time,
      target_type: formData.target_type.value,
      target_subtype: formData.target_subtype.value,
      taxonomy_level: formData.taxonomy_level.value,
    }
    console.log("Entering handleSubmit with this data :", restructuredData);

    setIsLoading(true);
    setError(false);

    const response = await fetch("http://52.72.29.115:3001/api/LO-submit", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify(restructuredData)
    });

    if (response.ok) {
      const data = await response.json();
      console.log('API response:', data);
      setIsLoading(false); // Hide loading bar
      navigate("/LessonAIpagelo15")
    }
    else {
      console.error('API request failed');
      setError(true);
      setIsLoading(false); // Hide loading bar
    }
  }

  const handleRetry = () => {
    setError(false);
    handleSubmit();
  };

  return (
    <>
      <Helmet>
        <title>LearnMigo</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full items-start justify-between gap-5 bg-white-A700 pr-[333px] md:flex-col md:pr-5">
        <Sidebar4 />
        <div className="mt-10 flex w-[43%] flex-col items-center md:w-full">
          <Text size="6xl" as="p" className="!text-[38px]">
            Create Learning Outcomes
          </Text>
          <div className="flex w-[91%] flex-col items-start gap-[7px] md:w-full md:p-5">
            <Text as="p" className="!text-[13px] !text-gray-500">
              Creates learning outcomes using Bloom&#39;s Taxonomy by Assessment
            </Text>
            {/* <form onSubmit={handleFormSubmit} id="LO-form"> */}
            <form onSubmit={handleFormSubmit} id="LO-form" className="flex flex-col gap-[19px] self-stretch">
              <div className="flex flex-col gap-2.5">
                <div className="flex flex-col items-start gap-1.5">
                  <Text as="p" className="!text-blue_gray-900_01">
                    Topic Name
                  </Text>
                  <Input
                    shape="round"
                    type="text"
                    name="topic"
                    placeholder={`Name of your Topic`}
                    className="!rounded-lg border-gray-400 font-hind sm:pr-5"
                    value={formData.topic}
                    onChange={topic => handleInputChange({ target: { value: topic, name: 'topic' } })}
                  />
                </div>
              </div>
              <div className="flex flex-col items-start gap-[7px]">
                <Text as="p" className="!text-blue_gray-900_01">
                  Time in Minutes
                </Text>
                <Input
                  shape="round"
                  name="time"
                  placeholder={`Enter the time you have to teach the topic`}
                  className="!rounded-lg border-gray-400 font-hind sm:pr-5"
                  value={formData.time}
                  onChange={time => handleInputChange({ target: { value: time, name: 'time' } })}
                />
              </div>
              <div className="flex flex-col items-start gap-1.5">
                <Text as="p" className="!text-blue_gray-900_01">
                  Target Students Type
                </Text>
                <SelectBox
                  shape="round"
                  indicator={<Img src="images/img_arrowdown.svg" alt="arrow_down" className="h-[20px] w-[20px]" />}
                  name="target_type"
                  placeholder={`K12, Test Preparation, College Student or Working Professional`}
                  options={dropDownOptions1}
                  className="gap-px self-stretch border border-solid border-gray-400 font-hind sm:pr-5"
                  value={formData.target_type}
                  onChange={(target_type) => handleSelectChange('target_type', target_type)}
                />
              </div>
              <div className="flex flex-col items-start gap-1.5">
                <Text as="p" className="!text-blue_gray-900_01">
                  Target Students Subtype
                </Text>
                <SelectBox
                  shape="round"
                  indicator={<Img src="images/img_arrowdown.svg" alt="arrow_down" className="h-[20px] w-[20px]" />}
                  name="target_subtype"
                  placeholder={`Grade or Professional field`}
                  options={dropDownOptions2}
                  className="gap-px self-stretch border border-solid border-gray-400 font-hind sm:pr-5"
                  value={formData.target_subtype}
                  onChange={(target_subtype) => handleSelectChange('target_subtype', target_subtype)}
                />
              </div>
              <div className="flex flex-col items-start gap-1.5">
                <div className="mr-[10px] flex items-start gap-1.5 md:ml-0">
                <Text as="p" className="!text-blue_gray-900_01">
                  Bloom’s Taxonomy Level to Achieve
                </Text>
                <button
                  type="button"
                  data-tooltip-id="infoTooltip"
                  data-tooltip-content="A hierarchical classification system used to categorize educational learning objectives"
                >
                  <Img
                    src="images/img_outline_interface_attention.svg"
                    alt="info"
                    className="h-[16px] w-[16px]"
                  />
                </button>
                {/* <Text as="p" className="!text-gray-500">
                  Click here for Help
                </Text> */}
                <Tooltip id="infoTooltip" place="top"  border="1px solid black" 
                className="!text-blue_gray-900_01"
                style={{ backgroundColor: "rgb(255, 255, 255)", color: "#000000" }}/>
                </div>
                <SelectBox
                  shape="round"
                  indicator={<Img src="images/img_arrowdown.svg" alt="arrow_down" className="h-[20px] w-[20px]" />}
                  name="taxonomy_level"
                  placeholder={`Select bloom's taxonomy level to achieve`}
                  options={dropDownOptions3}
                  className="gap-px self-stretch border border-solid border-gray-400 font-hind sm:pr-5"
                  value={formData.taxonomy_level}
                  onChange={(taxonomy_level) => handleSelectChange('taxonomy_level', taxonomy_level)}
                />
              </div>

              <div className="flex gap-[15px]">
                  <Button
                    color="white_A700"
                    shape="round"
                    className="w-full !rounded-lg border border-solid border-blue_gray-900_01 font-medium sm:px-5"
                    onClick={() => console.log("Cancel clicked")}
                  >
                    Cancel
                  </Button>
                  <Button
                  type="submit"
                  form="LO-form"
                  color="light_green_900"
                  shape="round"
                  onClick={() => alert("Created Learning Outcomes Successfully")}>
                    Submit
                  </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <CircularLoadingBar
        isLoading={isLoading}
        error={error}
        comment="Generating your Assessment, please wait..."
        onRetry={handleRetry} /> 
    </>
  );
}
