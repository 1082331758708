import React from "react";
import PropTypes from "prop-types";

const shapes = {
  round: "rounded-[10px]",
};
const variants = {
  fill: {
    gray_400: "bg-gray-400 text-white-A700",
    light_green_900: "bg-light_green-900 text-white-A700",
  },
  outline: {
    blue_gray_100: "border-blue_gray-100 border border-solid text-black-900",
  },
};
const sizes = {
  "2xl": "h-[46px] px-[35px] text-base",
};

const Button2 = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant = "fill",
  size = "2xl",
  color = "blue_gray_900",
  ...restProps
}) => {
  return (
    <button2
      className={`${className} flex flex-row items-center justify-center text-center cursor-pointer text-white-A700 text-base font-medium min-w-[207px] rounded-lg ${(shape && shapes[shape]) || ""} ${(size && sizes[size]) || ""} ${(variant && variants[variant]?.[color]) || ""}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button2>
  );
};

Button2.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  shape: PropTypes.oneOf(["round"]),
  size: PropTypes.oneOf(["2xl"]),
  variant: PropTypes.oneOf(["fill"]),
  color: PropTypes.oneOf(["gray_400", "light_green_900"]),
};

export { Button2 };
