// import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';

// const CircularLoadingBar = ({ isLoading, error, comment, onRetry }) => {
//   const [showError, setShowError] = useState(false);

//   useEffect(() => {
//     if (error) {
//       setShowError(true);
//     } else {
//       setShowError(false);
//     }
//   }, [error]);

//   const handleCloseError = () => {
//     setShowError(false);
//   };

//   return (
//     <>
//       {isLoading && (
//         <div className="fixed inset-0 flex flex-col items-center opacity-100  mr-[-25rem] mt-[25rem] bg-black z-50">
//           <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-24 w-24 mb-4"></div>
//           {comment && <p className="text-white text-lg">{comment}</p>}
//         </div>
//       )}
//       {showError && (
//         <div className="fixed inset-0 flex flex-col items-center opacity-100 mr-[-25rem] mt-[25rem] bg-black z-50">
//           <div className="bg-white opacity-100 p-6 rounded-lg shadow-lg">
//             <p className="text-red-500 text-lg mb-4">An error occurred. Please try again.</p>
//             <button
//               className="bg-blue-500 text-white py-2 px-4 rounded ml-[5.5rem]"
//               onClick={() => {
//                 handleCloseError();
//                 onRetry();
//               }}
//             >
//               Retry
//             </button>
//             <button
//               className="bg-gray-500 text-white py-2 px-4 rounded ml-[0.5rem]"
//               onClick={handleCloseError}
//             >
//               Close
//             </button>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// CircularLoadingBar.propTypes = {
//   isLoading: PropTypes.bool.isRequired,
//   error: PropTypes.bool.isRequired,
//   comment: PropTypes.string,
//   onRetry: PropTypes.func.isRequired,
// };

// export default CircularLoadingBar;


import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const CircularLoadingBar = ({ isLoading, error, comment, onRetry }) => {
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (error) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  }, [error]);

  const handleCloseError = () => {
    setShowError(false);
  };

  return (
    <>
      {isLoading && (
        <div className="fixed inset-0 flex flex-col items-center  mr-[-25rem] mt-[20rem] z-50">
          <div className="flex flex-col items-center justify-center bg-white-A700 p-6 rounded-lg shadow-lg">
            <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-24 w-24 mb-4"></div>
            {comment && <p className="text-gray-700 text-lg">{comment}</p>}
          </div>
        </div>
      )}
      {showError && (
        <div className="fixed inset-0 flex flex-col items-center  mr-[-25rem] mt-[20rem] bg-black z-50">
          <div className="bg-white-A700 p-6 rounded-lg shadow-lg">
            <p className="text-red-500 text-lg mb-4">An error occurred. Please try again.</p>
            <button
              className="bg-blue-500 text-white-A700 py-2 px-4 rounded ml-[5.5rem]"
              onClick={() => {
                handleCloseError();
                onRetry();
              }}
            >
              Retry
            </button>
            <button
              className="bg-gray-500 text-white-A700 py-2 px-4 rounded ml-[0.5rem]"
              onClick={handleCloseError}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

CircularLoadingBar.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  comment: PropTypes.string,
  onRetry: PropTypes.func.isRequired,
};

export default CircularLoadingBar;
