import React from "react";
import { useRoutes } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import SignUpPage from "pages/SignUpPage";
import SigninPage from "pages/SigninPage";
import MainDashboard from "pages/MainDashboard";
import ProfAIDashboardPageOne from "pages/ProfAIDashboardPageOne";
import ProfAIPage from "pages/ProfAIPage";
import ProfAIUpdatePage from "pages/ProfAIUpdatePage";
import MainDashboardOne from "pages/MainDashboardOne";
import LessonAIPageMain from "pages/LessonAIPageMain";
import LessonAIPageLOOne from "pages/LessonAIPageLOOne";
import LessonAIPageLO15 from "pages/LessonAIPageLO15";
import LessonAIPageLOTwo from "pages/LessonAIPageLOTwo";
import LessonAIPageLOThree from "pages/LessonAIPageLOThree";
import LessonAIPageLOThreeNew from "pages/LessonAIPageLOThreeNew";
import LessonAIPageFlowDocOne from "pages/LessonAIPageFlowDocOne";
import LessonAIPageFlowDocTwo from "pages/LessonAIPageFlowDocTwo";
import LessonAIPagePPTOne from "pages/LessonAIPagePPTOne";
import LessonAIPagePPTTwo from "pages/LessonAIPagePPTTwo";
import LessonAIPagePPTThree from "pages/LessonAIPagePPTThree";
import LessonAIPageVideoOne from "pages/LessonAIPageVideoOne";
import LessonAIPageVideoTwo from "pages/LessonAIPageVideoTwo";
import LessonAIPageCompletion from "pages/LessonAIPageCompletion";
import LessonAIPageAllLessons from "pages/LessonAIPageAllLessons";
import MaterialsPageMain from "pages/MaterialsPageMain";
import MaterialsUploadPage from "pages/MaterialsUploadPage"

const ProjectRoutes = () => {
  let element = useRoutes([
    { path: "/", element: <Home /> },
    { path: "*", element: <NotFound /> },
    {
      path: "signuppage",
      element: <SignUpPage />,
    },
    {
      path: "signinpage",
      element: <SigninPage />,
    },
    {
      path: "maindashboard",
      element: <MainDashboard />,
    },
    {
      path: "profaidashboardpageone",
      element: <ProfAIDashboardPageOne />,
    },
    {
      path: "profaipage",
      element: <ProfAIPage />,
    },
    {
      path: "profaiupdatepage",
      element: <ProfAIUpdatePage />,
    },
    {
      path: "maindashboardone",
      element: <MainDashboardOne />,
    },
    {
      path: "LessonAIpagemain",
      element: <LessonAIPageMain />,
    },
    {
      path: "LessonAIpageloone",
      element: <LessonAIPageLOOne />,
    },
    {
      path: "LessonAIpagelo15",
      element: <LessonAIPageLO15 />,
    },
    {
      path: "LessonAIpagelotwo",
      element: <LessonAIPageLOTwo />,
    },
    {
      path: "LessonAIpagelothree",
      element: <LessonAIPageLOThree />,
    },
    {
      path: "LessonAIpagelothree/:id",
      element: <LessonAIPageLOThree />,
    },
    {
      path: "LessonAIpagelothreenew",
      element: <LessonAIPageLOThreeNew />,
    },
    {
      path: "LessonAIpageflowdocone",
      element: <LessonAIPageFlowDocOne />,
    },
    {
      path: "LessonAIpageflowdoctwo",
      element: <LessonAIPageFlowDocTwo />,
    },
    {
      path: "LessonAIpageflowdoctwo/:id",
      element: <LessonAIPageFlowDocTwo />,
    },
    {
      path: "LessonAIpagepptone",
      element: <LessonAIPagePPTOne />,
    },
    {
      path: "LessonAIpageppttwo",
      element: <LessonAIPagePPTTwo />,
    },
    {
      path: "LessonAIpagepptthree",
      element: <LessonAIPagePPTThree />,
    },
    {
      path: "LessonAIpagepptthree/:id",
      element: <LessonAIPagePPTThree/>,
    },
    {
      path: "LessonAIpagevideoone",
      element: <LessonAIPageVideoOne />,
    },
    {
      path: "LessonAIpagevideotwo",
      element: <LessonAIPageVideoTwo />,
    },
    {
      path: "LessonAIpagevideotwo/:id",
      element: <LessonAIPageVideoTwo />,
    },
    {
      path: "LessonAIpagecompletion",
      element: <LessonAIPageCompletion />,
    },
    {
      path: "LessonAIpagealllessons",
      element: <LessonAIPageAllLessons />,
    },
    {
      path: "materialspagemain",
      element: <MaterialsPageMain />,
    },
    {
      path: "materialsuploadpage",
      element: <MaterialsUploadPage />,
    },
  ]);

  return element;
};

export default ProjectRoutes;
