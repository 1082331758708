import React from "react";
import { Img, Text } from "./..";
import { MenuItem, Menu, Sidebar, sidebarClasses } from "react-pro-sidebar";

export default function Sidebar2({ ...props }) {
  const [collapsed, setCollapsed] = React.useState(false);

  //use this function to collapse/expand the sidebar
  //function collapseSidebar() {
  //    setCollapsed(!collapsed)
  //}

  return (
    <Sidebar
      {...props}
      width="312px !important"
      collapsedWidth="80px !important"
      collapsed={collapsed}
      rootStyles={{ [`.${sidebarClasses.container}`]: { gap: 22 } }}
      className={`${props.className} flex flex-col h-screen pt-[7px] pl-[26px] pr-[25px] gap-[22px] top-0 md:p-5 sm:px-5 bg-gray-100 !sticky overflow-auto md:hidden`}
    >
      <Img
        src="images/img_learnmigo_logo_2.png"
        alt="learnmigologo"
        className="ml-3 h-[106px] w-[80%] object-cover md:ml-0"
      />
      <Text size="md" as="p" className="ml-[25px] tracking-[1.00px] !text-gray-900_7f md:ml-0">
        MENU
      </Text>
      <Menu
        menuItemStyles={{
          button: {
            padding: "10px 10px 10px 24px",
            gap: "15px",
            opacity: 0.6,
            color: "#273240",
            fontWeight: 400,
            fontSize: "12px",
            borderRadius: "5px",
            [`&:hover, &.ps-active`]: { backgroundColor: "#707fdd19 !important" },
          },
        }}
        className="w-full self-stretch"
      >
        <div className="flex flex-col gap-[0.81px]">
          <MenuItem icon={<Img src="images/img_bookmark.svg" alt="bookmark" className="h-[24px] w-[24px]" />}>
            LessonAI
          </MenuItem>
          <MenuItem icon={<Img src="images/img_thumbs_up.svg" alt="thumbsup" className="h-[24px] w-[24px]" />}>
            ProfAI
          </MenuItem>
          <MenuItem icon={<Img src="images/img_menu.svg" alt="menu" className="h-[24px] w-[24px]" />}>
            Your Materials
          </MenuItem>
          <MenuItem icon={<Img src="images/img_user.svg" alt="user" className="h-[24px] w-[24px]" />}>
            Your Classes
          </MenuItem>
        </div>
        <div className="flex flex-col items-start pt-4">
          <Text size="md" as="p" className="ml-[25px] tracking-[1.00px] !text-gray-900_7f md:ml-0">
            OTHERS
          </Text>
          <MenuItem icon={<Img src="images/img_info.svg" alt="info" className="h-[24px] w-[24px]" />}>
            Settings
          </MenuItem>
          <MenuItem icon={<Img src="images/img_lock.svg" alt="lock" className="h-[24px] w-[24px]" />}>
            Your Account
          </MenuItem>
          <MenuItem
            icon={<Img src="images/img_outline_interface_info.svg" alt="outline" className="h-[24px] w-[24px]" />}
          >
            Help
          </MenuItem>
        </div>
      </Menu>
    </Sidebar>
  );
}
