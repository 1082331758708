import React, { useState, useRef, useEffect } from "react";
import { Button, Text, Img } from "../../components";

const ProfilePictureUpload = ({ onPictureChange, initialPicture }) => {
  const [profilePicture, setProfilePicture] = useState(null);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null); // Reference to the file input element

  useEffect(() => {
    // Set the initial value of profilePicture based on the initialPicture prop
    setProfilePicture(initialPicture);
  }, [initialPicture]);

  const handleButtonClick = () => {
    // Trigger click event on the hidden file input
    fileInputRef.current.click();
  };

  const handlePictureChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (file.size > 10 * 1024 * 1024) {
        // Check if file size is greater than 10MB
        setError("File size exceeds 10MB limit.");
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        setProfilePicture(reader.result);
        setError(null);
        onPictureChange(reader.result); // Pass the base64-encoded string to the parent component
      };
      reader.onerror = () => {
        setError("Failed to read the file.");
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="flex flex-col items-center gap-1.5">
      <Text as="p" className="!text-blue_gray-900_01">
        Profile Picture
      </Text>
      <Button color="blue_gray_100" size="4xl" variant="outline" shape="circle" className="w-[78px] !rounded-[39px] sm:px-5" type='button' onClick={handleButtonClick}>
        <input type="file" ref={fileInputRef} id="profilePictureInput" accept="image/*" onChange={handlePictureChange} style={{ display: "none" }} />
        <div className="img-wrap img-upload ">
          <Img src={profilePicture || "images/img_plus.svg"} alt="Upload" className="h-[58px] max-w-[100px] rounded-full object-cover" />
        </div>
      </Button>
      {error && <Text className="text-red-500">{error}</Text>}
    </div>
  );
};

export default ProfilePictureUpload;
