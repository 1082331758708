import React from "react";
import { Helmet } from "react-helmet";
import { Button, Text, Img } from "../../components";
import Sidebar3 from "../../components/Sidebar3";

export default function MainDashboardOnePage() {
  return (
    <>
      <Helmet>
        <title>LearnMigo</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full items-start justify-between gap-5 bg-white-A700 pr-[292px] md:flex-col md:pr-5">
        <Sidebar3 className="!sticky top-0 flex h-screen w-[122px] flex-col overflow-auto bg-gray-100 pt-[29px] md:hidden md:p-5 sm:pt-5" />
        <div className="mt-[41px] flex w-[80%] flex-col items-start gap-10 md:w-full">
          <Text size="6xl" as="p">
            Main Dashboard
          </Text>
          <div className="flex justify-between gap-5 self-stretch md:flex-col">
            <div className="flex h-[195px] w-[47%] items-center rounded-[7px] bg-[url(/public/images/img_card.svg)] bg-cover bg-no-repeat px-[34px] pb-5 pt-[23px] md:h-auto md:w-full md:p-5 sm:px-5 sm:pt-5">
              <div className="flex w-[60%] flex-col items-start md:w-full">
                <Text size="3xl" as="p" className="!text-[16.5px]">
                  Your LessonAI Lessons
                </Text>
                <Text
                  size="s"
                  as="p"
                  className="mt-2 w-full !font-dmsans !text-[10.5px] leading-[15px] !text-black-900_66"
                >
                  <>
                    Lessons Created : #1 Lesson1Name
                    <br /> #2 Lesson2Name
                    <br /> #3 Lesson3Name
                  </>
                </Text>
                <Button size="xs" className="mt-[46px] min-w-[145px] rounded font-medium sm:px-5">
                  View your Lessons
                </Button>
              </div>
            </div>
            <div className="flex h-[195px] w-[47%] items-center justify-center rounded-[7px] bg-[url(/public/images/img_card.svg)] bg-cover bg-no-repeat px-[23px] pb-5 pt-[23px] md:h-auto md:w-full md:p-5 sm:px-5 sm:pt-5">
              <div className="flex w-full flex-col items-start gap-[17px]">
                <Text size="3xl" as="p" className="!text-[16.5px]">
                  Your ProfAI
                </Text>
                <div className="flex w-[84%] items-start gap-[26px] md:w-full">
                  <div className="flex w-[22%] justify-center rounded-[30px] border border-solid border-blue_gray-100">
                    <Img src="images/img_image_1.png" alt="imageoneone" className="h-[58px] w-full rounded-[50%]" />
                  </div>
                  <Text
                    size="s"
                    as="p"
                    className="w-[78%] !font-dmsans !text-[10.5px] leading-[15px] !text-black-900_66"
                  >
                    <>
                      Name : Albert Einstein
                      <br />
                      Description : I am good at physics and math.
                      <br />
                      Hello, How do you do?
                    </>
                  </Text>
                </div>
                <div className="flex items-center self-stretch">
                  <Text size="md" as="p" className="!text-[11.25px] !font-medium">
                    Share your Avatar :
                  </Text>
                  <Img
                    src="images/img_thumbs_up_black_900.svg"
                    alt="thumbsupthree"
                    className="ml-3 mt-[5px] h-[15px] w-[14px] self-start"
                  />
                  <Img
                    src="images/img_volume.svg"
                    alt="volumeone"
                    className="ml-[5px] mt-[5px] h-[15px] w-[15px] self-start"
                  />
                  <Button size="sm" className="ml-4 w-full flex-1 rounded font-medium sm:px-5">
                    Update
                  </Button>
                  <Button size="sm" className="ml-[17px] w-full flex-1 rounded font-medium">
                    Add Materials
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex h-[195px] w-[47%] items-center justify-center rounded-[7px] bg-[url(/public/images/img_card.svg)] bg-cover bg-no-repeat px-[23px] pb-[61px] pt-[23px] md:h-auto md:w-full md:pb-5 sm:p-5">
            <div className="flex w-[85%] flex-col items-start md:w-full">
              <Text size="3xl" as="p" className="!text-[16.5px]">
                Your Materials
              </Text>
              <Text size="s" as="p" className="mt-2 !font-dmsans !text-[10.5px] !text-black-900_66">
                Subject : Science Topics added : Physics, Chemistry{" "}
              </Text>
              <div className="mt-9 flex justify-between gap-5 self-stretch">
                <Button size="sm" className="min-w-[145px] rounded font-medium sm:px-5">
                  View Materials
                </Button>
                <Button size="xs" className="min-w-[145px] rounded font-medium sm:px-5">
                  Upload Materials
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
