import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import { Button, Input, Text, SelectBox, Img, FileUpload } from "../../components";
import Sidebar2 from "../../components/Sidebar2";
import ProfilePictureUpload from './ProfilePictureUpload';
import { HiOutlineArrowUpCircle } from "react-icons/hi2";

const dropDownOptionsLS = [
  { label: "Verbal", value: "verbal" }, 
  { label: "Active", value: "active" },
  { label: "Intuitive", value: "intuitive" },
  { label: "Reflective", value: "reflective" },
  { label: "Global", value: "global" }
];

const dropDownOptionsCom = [
  { label: "Layman", value: "layman" },
  { label: "Story Telling", value: "story telling" },
  { label: "Socratic", value: "socratic" },
];

const dropDownOptionsInt = [
  { label: "Encouraging", value: "encouraging" },
  { label: "Informative", value: "informative" },
  { label: "Friendly", value: "friendly" },
  { label: "Humorous", value: "humorous" },
  { label: "Empathetic", value: "empathetic" }
];

const dropDownOptionsRF = [
  { label: "Deductive", value: "deductive" },
  { label: "Inductive", value: "inductive" },
  { label: "Abductive", value: "abductive" },
  { label: "Analogical", value: "analogical" },
  { label: "Causal", value: "causal" }
];

const dropDownOptionsSub = [
  { label: "Maths", value: "maths" },
  { label: "Science", value: "science" },
  { label: "Chemistry", value: "chemistry" },
];

const dropDownOptionsQual = [
  { label: "Undergrad", value: "Undergrad" }, 
  { label: "Postgrad", value: "Postgrad" },
  { label: "High School", value: "High School" },
];
  

export default function ProfAIPagePage() {

  const [formData, setFormData] = useState({
    name: '',
    profilePicture: null,
    description: '',
    greeting: '',
    learningStyles: [],
    communication: '',
    interaction: '',
    reasoningFrameworks: [],
    subjects: [],
    rules: '',
    studentDescription: '',
    conversationStarters: '',
    uploadedFiles: []
  });

  const handleFileUpload = (files) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      uploadedFiles: files,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (!name) {
      // Handle the case where 'name' is undefined
      console.error("Input field has no 'name' attribute.");
      return;
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (name, value) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: Array.isArray(value) ? value : value || ''
    }));
  };

  const handleProfilePictureChange = (picture) => {
    setFormData({ ...formData, profilePicture: picture });
  };
  
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    await handleSubmit();
  };

  // const handleSubmit = async () => {

  //   console.log("Entering handleSubmit with this data :", formData.uploadedFiles)

  //   try {
  //     const restructuredData = {
  //       name: formData.name,
  //       profile_picture: formData.profilePicture,
  //       description: formData.description,
  //       greeting: formData.greeting,
  //       learning_style: formData.learningStyles.value || '', 
  //       communication: formData.communication.value,
  //       tone: formData.interaction.value, 
  //       reasoning_frameworks: formData.reasoningFrameworks.value || '', 
  //       subjects: formData.subjects.value || '', 
  //       rules: formData.rules,
  //       starters: formData.conversationStarters, 
  //       student_description: formData.studentDescription.value,
  //       uploaded_files: formData.uploadedFiles,
  //     };
  
  //     const response = await fetch('http://52.72.29.115:3001/api/submit', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(restructuredData),
  //     });
  
  //     if (response.ok) {
  //       const data = await response.json();
  //       console.log('API response:', data);
  //       // Handle the API response data as needed
  //       console.log('Avatar data saved successfully');
  //     } else {
  //       console.error('API request failed');
  //       console.error('Failed to save avatar data');
  //     }
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };

  const handleSubmit = async () => {
    console.log("Entering handleSubmit with this data :", formData);
  
    try {
      const restructuredData = {
        name: formData.name,
        profile_picture: formData.profilePicture,
        description: formData.description,
        greeting: formData.greeting,
        learning_style: formData.learningStyles.value || '',
        communication: formData.communication.value,
        tone: formData.interaction.value,
        reasoning_frameworks: formData.reasoningFrameworks.value || '',
        subjects: formData.subjects.value || '',
        rules: formData.rules,
        starters: formData.conversationStarters,
        student_description: formData.studentDescription.value
      };
  
      const formDataToSend = new FormData();
    formDataToSend.append('data', JSON.stringify(restructuredData));

    for (const file of formData.uploadedFiles) {
      formDataToSend.append('uploaded_files', file);
    }
  
    const response = await fetch('http://52.72.29.115:3001/api/submit', {
      method: 'POST',
      body: formDataToSend,
    });

    if (response.ok) {
      const data = await response.json();
      console.log('API response:', data);
      console.log('Avatar data saved successfully');
      console.log(formData.uploadedFiles);

      const clientId = data.client_id;

      // Call the route to send uploaded files to the API
      const sendFilesFormData = new FormData();
      sendFilesFormData.append('assistant_id', clientId);
      for (const file of formData.uploadedFiles) {
        sendFilesFormData.append('uploaded_files', file);
      }

      const sendFilesResponse = await fetch('http://52.72.29.115:3001/api/send-uploaded-files', {
        method: 'POST',
        body: sendFilesFormData,
      });

      if (sendFilesResponse.ok) {
        const sendFilesData = await sendFilesResponse.json();
        console.log('Uploaded files sent to API:', sendFilesData);
      } else {
        console.error('Failed to send uploaded files to API');
      }

    } else {
      console.error('API request failed');
      console.error('Failed to save avatar data');
    }
  } catch (error) {
    console.error('Error:', error);
  }
  };

  return (
    <>
      <Helmet>
        <title>LearnMigo</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full items-start justify-between gap-5 bg-white-A700 pr-[353px] md:flex-col md:pr-5">
        <Sidebar2 className="!sticky top-0 flex h-screen w-[312px] flex-col gap-[22px] overflow-auto bg-gray-100 pl-[26px] pr-[25px] pt-[7px] md:hidden md:p-5 sm:px-5" />
        <div className="flex w-[43%] flex-col items-center gap-7 md:w-full">
          <div className="flex flex-col items-center gap-[3px] self-stretch">
            <Text size="6xl" as="p" className="!text-[38px]">
              Tell Us About Your Avatar
            </Text>
            <form onSubmit={handleFormSubmit} id="avatar-form" className="flex flex-col gap-[19px] self-stretch">
              <div className="relative h-[202px]">
                <div className="absolute bottom-[-0.29px] left-0 right-0 m-auto flex w-full flex-col items-end">
                    <div className="relative z-[1] flex h-[80px] w-[370px] -mb-3 flex-col items-start md:w-full md:p-5">
                      <Text as="p" className="relative z-[2] tracking-[0.50px] !text-blue_gray-900_01">
                        Upload Materials{" "}
                      </Text>
                      <FileUpload
                        allowMultiple
                        preview
                        name="uploadedFiles"
                        // Thumbnail={FileUpload.PreviewItem}
                        // Thumbnail={"Files Successfully Uploaded"}
                        // onUpload={(files) => console.log({ files })}
                        onUpload={handleFileUpload}
                        // placeholder={() => <>[object Object] [object Object]</>}
                        className="relative flex h-[46px] items-center gap-2 self-stretch rounded-lg border border-solid border-gray-400 bg-white-A700 px-4 py-[11px]"
                        
                      >
                        <Img src="images/img_close.svg" alt="closeone" className="h-[23px] w-[24px]" />
                        <div className="flex">
                          <Text as="p" className="!font-normal !text-gray-500">
                            Upload your Materials for ProfAI
                          </Text>
                        </div>
                      </FileUpload>
                    </div>
                  <div className="relative mt-[-18px] flex flex-col items-start gap-1.5 self-stretch">
                    <Text as="p" className="!text-blue_gray-900_01">
                      Short Description
                    </Text>
                    <Input
                      type='text'
                      shape="round"
                      name="description"
                      placeholder={`Description`}
                      className="!rounded-lg border-gray-400 font-hind sm:pr-5"
                      value={formData.description}
                      onChange={description => handleInputChange({ target: { value: description, name: 'description' } })}
                    />
                  </div>
                </div>
                <div className="absolute right-[0.00px] top-[0.31px] m-auto h-[76px] w-[69%] md:h-auto">
                  <div className="ml-[43px] flex items-start gap-[7px] md:ml-0">
                    <Img
                      src="images/img_outline_interface_attention.svg"
                      alt="outlinethree"
                      className="h-[16px] w-[16px]"
                    />
                    <Text as="p" className="!text-gray-500">
                      Click here for Help
                    </Text>
                  </div>
                  <div className="absolute bottom-0 left-0 right-0 top-0 m-auto flex h-max w-full flex-col items-start gap-[7px]">
                    <Text as="p" className="!text-blue_gray-900_01">
                      Name
                    </Text>
                    <Input
                      shape="round"
                      type="text"
                      name="name"
                      placeholder={`Name of your Avatar`}
                      className="!rounded-lg border-gray-400 font-hind sm:pr-5"
                      value={formData.name}
                      onChange={name => handleInputChange({ target: { value: name, name: 'name' } })}
                    />
                  </div>
                </div>
                <div className="absolute left-[0.00px] top-[0.00px] m-auto flex flex-col items-center gap-1.5">
                <ProfilePictureUpload onPictureChange={handleProfilePictureChange} />
                </div>
              </div>
              <div className="flex flex-col items-start gap-1.5">
                <Text as="p" className="!text-blue_gray-900_01">
                  How would you like your Avatar to greet?
                </Text>
                <Input
                  shape="round"
                  name="greeting"
                  placeholder={`Enter how would you like your avatar to greet?...`}
                  className="!rounded-lg border-gray-400 font-hind sm:pr-5"
                  onChange={greeting => handleInputChange({ target: { value: greeting, name: 'greeting' } })}
                  value={formData.greeting}
                />
              </div>
              <div className="flex items-center gap-4 sm:flex-col">
                <div className="flex w-full flex-col items-start gap-1.5 sm:w-full sm:p-5">
                  <Text as="p" className="!text-blue_gray-900_01">
                    Learning Styles
                  </Text>
                  <SelectBox
                    shape="round"
                    indicator={<Img src="images/img_arrowdown.svg" alt="arrow_down" className="h-[20px] w-[20px]" />}
                    name="learningStyles"
                    placeholder={`Choose learning styles...`}
                    options={dropDownOptionsLS}
                    className="gap-px self-stretch border border-solid border-gray-400 font-hind sm:pr-5"
                    value={formData.learningStyles}
                    onChange={(learningStyles) => handleSelectChange('learningStyles', learningStyles)}
                  />
                </div>
                <div className="flex w-full flex-col items-start gap-[7px] sm:w-full sm:p-5">
                  <Text as="p" className="!text-blue_gray-900_01">
                    Communication
                  </Text>
                  <SelectBox
                    shape="round"
                    indicator={<Img src="images/img_arrowdown.svg" alt="arrow_down" className="h-[20px] w-[20px]" />}
                    name="communication"
                    placeholder={`Enter communication...`}
                    options={dropDownOptionsCom}
                    className="gap-px self-stretch border border-solid border-gray-400 font-hind sm:pr-5"
                    value={formData.communication}
                    onChange={communication => handleInputChange({ target: { value: communication, name: 'communication' } })}
                  />
                </div>
              </div>
              <div className="flex flex-col items-start gap-1.5">
                <Text as="p" className="!text-blue_gray-900_01">
                  How would you like your avatar to interact with them?
                </Text>
                <SelectBox
                  shape="round"
                  indicator={<Img src="images/img_arrowdown.svg" alt="arrow_down" className="h-[20px] w-[20px]" />}
                  name="interaction"
                  placeholder={`Suggest a tone, level of detail, tutoring style, etc.`}
                  options={dropDownOptionsInt}
                  className="gap-px self-stretch border border-solid border-gray-400 font-hind sm:pr-5"
                  value={formData.interaction}
                  onChange={interaction => handleInputChange({ target: { value: interaction, name: 'interaction' } })}
                />
              </div>
              <div className="flex flex-col items-start gap-1.5">
                <Text as="p" className="!text-blue_gray-900_01">
                  Reasoning Frameworks
                </Text>
                <SelectBox
                  shape="round"
                  indicator={<Img src="images/img_arrowdown.svg" alt="arrow_down" className="h-[20px] w-[20px]" />}
                  name="reasoningFrameworks"
                  placeholder={`Choose reasoning frameworks...`}
                  options={dropDownOptionsRF}
                  className="gap-px self-stretch border border-solid border-gray-400 font-hind sm:pr-5"
                  value={formData.reasoningFrameworks}
                  onChange={(reasoningFrameworks) => handleSelectChange('reasoningFrameworks', reasoningFrameworks)}
                />
              </div>
              <div className="flex flex-col items-start gap-1.5">
                <Text as="p" className="!text-blue_gray-900_01">
                  Which subjects do you teach?
                </Text>
                <SelectBox
                  shape="round"
                  indicator={<Img src="images/img_arrowdown.svg" alt="arrow_down" className="h-[20px] w-[20px]" />}
                  name="subjects"
                  placeholder={`Choose which subjects do you teach?...`}
                  options={dropDownOptionsSub}
                  className="gap-px self-stretch border border-solid border-gray-400 font-hind sm:pr-5"
                  value={formData.subjects}
                  onChange={(subjects) => handleSelectChange('subjects', subjects)}
                />
              </div>
              <div className="flex flex-col items-start gap-1.5">
                <Text as="p" className="!text-blue_gray-900_01">
                  What rules do you want to set for your Avatar?
                </Text>
                <Input
                  shape="round"
                  name="rules"
                  placeholder={`Establish clear rules for interactions with your students.`}
                  className="!rounded-lg border-gray-400 font-hind sm:pr-5"
                  value={formData.rules}
                  onChange={rules => handleInputChange({ target: { value: rules, name: 'rules' } })}
                />
              </div>
              <div className="flex flex-col items-start gap-1.5">
                <Text as="p" className="!text-blue_gray-900_01">
                  How would you describe your students?
                </Text>
                <SelectBox
                  shape="round"
                  indicator={<Img src="images/img_arrowdown.svg" alt="arrow_down" className="h-[20px] w-[20px]" />}
                  name="studentDescription"
                  placeholder={`Choose their Current Qualification`}
                  options={dropDownOptionsQual}
                  className="gap-px self-stretch border border-solid border-gray-400 font-hind sm:pr-5"
                  value={formData.studentDescription}
                  onChange={studentDescription => handleInputChange({ target: { value: studentDescription, name: 'studentDescription' } })}
                />
              </div>
              <div className="flex flex-col items-start gap-1.5">
                <Text as="p" className="!text-blue_gray-900_01">
                  Conversation Starters for you students
                </Text>
                <Input
                  shape="round"
                  name="conversationStarters"
                  placeholder={`eg. “Hello students, what doubts do you want me to resolve? ”`}
                  className="!rounded-lg border-gray-400 font-hind sm:pr-5"
                  value={formData.conversationStarters}
                  onChange={conversationStarters => handleInputChange({ target: { value: conversationStarters, name: 'conversationStarters' } })}
                />
              </div>
            </form>
          </div>
          <div className="flex gap-[15px]">
              <Button
                color="white_A700"
                shape="round"
                className="min-w-[207px] !rounded-lg border border-solid border-blue_gray-900_01 font-medium sm:px-5"
                onClick={() => console.log("Cancel clicked")}
              >
                Cancel
              </Button>
              <Button 
              type="submit"
              form="avatar-form"
              color="light_green_900"
              shape="round"
              onClick={() => alert("Created Avatar Successfully")}
              >
                Submit
              </Button>
          </div>
        </div>
      </div>
    </>
  );
}
