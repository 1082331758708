import { React, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {  Text } from "../../components";
import LessonAIPageLOThreeSublearning from "../../components/LessonAIPageLOThreeSublearning";
import Sidebar4 from "../../components/Sidebar4";

export default function LessonAIPageLOThreePage() {
  const [final_learningOutcomes, setfinal_LearningOutcomes] = useState([]);

  useEffect(() => {
    fetch('http://52.72.29.115:3001/api/learning-outcomes')
      .then(response => {
        console.log('API response status:', response.status);
        return response.json();
      })
      .then(data => {
        console.log('API response data:', data);
        setfinal_LearningOutcomes(data.learningOutcomes || []);
      })
      .catch(error => console.error('Error fetching final learning outcomes:', error));
  }, []);

  console.log('Final Learning Outcomes State:', final_learningOutcomes);

  return (
    <>
      <Helmet>
        <title>LearnMigo</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="w-full bg-white-A700_03 pr-[70px] md:pr-5">
        <div className="flex items-start justify-between gap-5 md:flex-col">
          <Sidebar4 />
          <div className="mt-[79px] flex w-[72%] flex-col items-center gap-[34px] md:w-full md:p-5">
            <Text size="6xl" as="p" className="mt-[-40px] !font-hind !text-black-900_02">
              These Are Your Learning Outcomes :
            </Text>
            {final_learningOutcomes.length === 0 ? (
              <Text size="4xl" as="p" className="text-center !font-hind !text-black-900_02">
                No learning outcomes available.
              </Text>
            ) : (
              final_learningOutcomes.map((outcome, index) => (
                <div key={index} className="self-stretch rounded-[20px] border border-solid border-blue_gray-100_02 bg-gray-100_04 p-[13px] mb-[34px]">
                  <div className="mb-[7px] flex flex-col gap-0.5">
                    <div className="flex justify-center pr-5">
                      <Text size="5xl" as="p" className="text-left !font-hind !text-[22px] !text-black-900_02">
                        <span className="text-black-900_02">Terminal Outcome :&nbsp;</span>
                        <span className="text-base font-normal text-black-900_02 mr-[140px]">
                          {outcome.terminalOutcome}
                        </span>
                      </Text>
                    </div>
                    {outcome.subLearningOutcomes.map((subOutcome, subIndex) => (
                      <LessonAIPageLOThreeSublearning
                        key={subIndex}
                        sublearningoutcome={`Sub-Learning Outcome: ${subOutcome.id}`}
                        outcome={subOutcome.outcome}
                        examplesdescription={subOutcome.examples.join(', ')}
                        modeofdelivery={subOutcome.modeOfDelivery}
                        timerequired={subOutcome.timeRequired}
                      />
                    ))}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
}
