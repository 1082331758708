import React from "react";
import { Img } from "./..";
import { MenuItem, Menu, Sidebar, sidebarClasses } from "react-pro-sidebar";

export default function Sidebar3({ ...props }) {
  const [collapsed, setCollapsed] = React.useState(false);

  //use this function to collapse/expand the sidebar
  //function collapseSidebar() {
  //    setCollapsed(!collapsed)
  //}

  return (
    <Sidebar
      {...props}
      width="122px !important"
      collapsedWidth="80px !important"
      collapsed={collapsed}
      className={`${props.className} flex flex-col h-screen pt-[29px] top-0 md:p-5 sm:pt-5 bg-gray-100 !sticky overflow-auto md:hidden`}
    >
      <Menu
        menuItemStyles={{
          button: {
            padding: 0,
          },
        }}
        rootStyles={{ ["&>ul"]: { gap: "0.04px" } }}
        className="flex w-full flex-col self-stretch"
      >
        <MenuItem
          icon={
            <Img src="images/img_sidebar_logo.png" alt="sidebarlogo" className="h-[30px] w-[65px] object-contain" />
          }
        />
        <MenuItem icon={<Img src="images/img_bookmark.svg" alt="bookmark" className="h-[30px] w-[65px]" />} />
        <MenuItem icon={<Img src="images/img_thumbs_up.svg" alt="thumbsup" className="h-[30px] w-[65px]" />} />
        <MenuItem icon={<Img src="images/img_menu.svg" alt="menu" className="h-[30px] w-[65px]" />} />
        <MenuItem icon={<Img src="images/img_user.svg" alt="user" className="h-[30px] w-[65px]" />} />
        <MenuItem icon={<Img src="images/img_info.svg" alt="info" className="h-[30px] w-[65px]" />} />
        <MenuItem icon={<Img src="images/img_lock.svg" alt="lock" className="h-[30px] w-[65px]" />} />
        <MenuItem
          icon={<Img src="images/img_outline_interface_info.svg" alt="outline" className="h-[30px] w-[65px]" />}
        />
      </Menu>
    </Sidebar>
  );
}
