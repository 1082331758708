import { Helmet } from "react-helmet";
import { Text, Img, Button } from "../../components";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar4 from '../../components/Sidebar4';
import axios from 'axios';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";

export default function LessonAIPagePPTThreePage() {
  const [pptData, setPptData] = useState([]);
  const [selectedPpt, setSelectedPpt] = useState(null);

  const navigate = useNavigate();
  const { id } = useParams(); // Get the ObjectId from the URL

  useEffect(() => {
    fetchPPTData();
  }, [id]);

  const fetchPPTData = async () => {
    const token = localStorage.getItem('token');
      console.log('token:', token);
    try {
      let response;
      if (id) {
        response = await axios.get(`http://52.72.29.115:3001/api/get-ppt-content/${id}`,
          { headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`} });
      } else {
        response = await axios.get('http://52.72.29.115:3001/api/ppt_data',
          { headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`} });
      }
      setPptData(response.data);
    } catch (error) {
      console.error('Error fetching PPT data:', error);
    }
  };

  const handleThumbnailClick = (ppt) => {
    setSelectedPpt(ppt);
  };

  const handleEditClick = () => {
    if (selectedPpt) {
      navigate("/LessonAIpageppttwo", { state: { ppt: selectedPpt } });
    }
  };
  
  return (
    <>
      <Helmet>
        <title>LearnMigo</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="w-full bg-white-A700 pr-[46px] md:pr-5">
        <div className="flex items-start justify-between gap-5">
          <Sidebar4 />
          <div className="flex w-[74%] flex-col items-center gap-[46px]">
            <Text size="6xl" as="p" className="!font-hind">
              Your Presentations
            </Text>
            <div className="flex flex-col gap-[30px] self-stretch">
              <div>
                <div className="flex flex-col gap-3">
                  <div className="flex flex-col items-center gap-7 md:flex-col">
                    <div className="w-full h-[500px] border border-solid border-black-900 shadow-xs md:self-stretch md:p-5">
                      {selectedPpt && (
                        <DocViewer
                          documents={[{ uri: selectedPpt.pptUrl, fileType: 'pptx' }]}
                          pluginRenderers={DocViewerRenderers}
                          style={{ width: "100%", height: "100%" }}
                        />
                      )}
                    </div>
                    <div className="flex justify-center gap-5 mt-5 w-full">
                      <Button shape="round" className="!rounded-lg" onClick={() => window.location.href = selectedPpt?.pptUrl}>
                        Download
                      </Button>
                      <Button shape="round" className="!rounded-lg" onClick={handleEditClick}>
                        Edit
                      </Button>
                      <Button
                        color="light_green_900"
                        shape="round"
                        className="w-[8rem]"
                        onClick={() => navigate("/LessonAIpagevideoone")}>
                          Next
                    </Button>
                    </div>
                  </div>
                  <div className="grid grid-cols-3 justify-center gap-[50px] md:grid-cols-2 sm:grid-cols-1 mt-10">
                    {pptData.map((ppt, index) => (
                      <div
                        key={"gridsublearning" + index}
                        className="relative h-[148px] w-full border border-solid border-black-900 shadow-xs md:h-auto"
                        onClick={() => handleThumbnailClick(ppt)}
                      >
                        <Img src={ppt.thumbnailUrl} alt="image" className="h-[148px] w-full object-contain" />
                        <Text as="p" className="text-shadow-ts absolute bottom-[12.00px] left-0 right-0 m-auto w-max">
                          {`Sublearning Outcome - ${index + 1} PPT`}
                        </Text>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

