import React from "react";

const sizes = {
  "5xl": "text-[32px] font-medium md:text-3xl sm:text-[28px]",
  xs: "text-[9px] font-medium",
  lg: "text-xs font-normal",
  "6xl": "text-[41px] font-medium md:text-[37px] sm:text-[31px]",
  s: "text-[10px] font-normal",
  "2xl": "text-sm font-medium",
  "3xl": "text-base font-medium",
  "4xl": "text-3xl font-medium md:text-[28px] sm:text-[26px]",
  xl: "text-[13px] font-medium",
  md: "text-[11px] font-normal",
};

const Text = ({ children, className = "", as, size = "2xl", ...restProps }) => {
  const Component = as || "p";

  return (
    <Component className={`text-black-900 font-hind ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Text };
