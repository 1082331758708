import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Button, Text } from "../../components";
import SideBarPA from "../../components/SideBarPA";

export default function ProfAIDashboardPageOnePage() {

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/profaipage"); 
  };

  return (
    <>
      <Helmet>
        <title>LearnMigo</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full items-start justify-between gap-5 bg-white-A700 pr-[264px] md:flex-col md:pr-5">
        <SideBarPA className="!sticky top-0 flex h-screen w-[312px] flex-col gap-[17px] overflow-auto bg-gray-100 pl-[26px] pr-[25px] pt-[5px] md:hidden md:p-5 sm:px-5" />
        <div className="right-[50px] m-auto flex flex-col items-center justify-center mr-1 gap-[29px]">
          <Text size="6xl" as="p">
            You Haven’t Created An Avatar Yet
          </Text>
            <Button size="xl" className="min-w-[209px] rounded-md font-medium sm:px-5" onClick={handleButtonClick} >
              Go to ProfAI
            </Button>
        </div>
      </div>
    </>
  );
}
