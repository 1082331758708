import React from "react";
import { Helmet } from "react-helmet";
import { Img, Text, Heading, Button } from "../../components";
import { ReactTable } from "../../components/ReactTable";
import Sidebar1 from "../../components/Sidebar1";
import { createColumnHelper } from "@tanstack/react-table";

const tableData = [
  { materialid: "#1", uploaded: "Material1Name", createdon: "18/04/2024", action: "images/img_trash_2_2.svg" },
  { materialid: "#2", uploaded: "Material2Name", createdon: "18/04/2024", action: "images/img_trash_2_2.svg" },
  { materialid: "#3", uploaded: "Material3Name", createdon: "18/04/2024", action: "images/img_trash_2_2.svg" },
];

export default function MaterialsPageMainPage() {
  const tableColumns = React.useMemo(() => {
    const tableColumnHelper = createColumnHelper();
    return [
      tableColumnHelper.accessor("materialid", {
        cell: (info) => (
          <Text size="xl" as="p" className="!font-montserrat !text-[13.72px] md:p-5">
            {info?.getValue?.()}
          </Text>
        ),
        header: (info) => (
          <Heading as="h1" className="py-[13px] pl-[35px] !text-[12.32px] sm:pl-5">
            Material ID
          </Heading>
        ),
        meta: { width: "190px" },
      }),
      tableColumnHelper.accessor("uploaded", {
        cell: (info) => (
          <Text size="xl" as="p" className="!font-montserrat !text-[13.72px] md:p-5">
            {info?.getValue?.()}
          </Text>
        ),
        header: (info) => (
          <Heading as="h2" className="py-[13px] !text-[12.32px]">
            Uploaded Material
          </Heading>
        ),
        meta: { width: "179px" },
      }),
      tableColumnHelper.accessor("createdon", {
        cell: (info) => (
          <Text size="xl" as="p" className="!font-montserrat !text-[13.72px] md:p-5">
            {info?.getValue?.()}
          </Text>
        ),
        header: (info) => (
          <Heading as="h3" className="px-px py-[13px] !text-[12.32px]">
            Created on
          </Heading>
        ),
        meta: { width: "330px" },
      }),
      tableColumnHelper.accessor("action", {
        cell: (info) => (
          <div className="flex md:p-5">
            <Img src={info?.getValue?.()} alt="trash2twoone" className="h-[23px] w-[23px]" />
          </div>
        ),
        header: (info) => (
          <Heading as="h4" className="py-[13px] !text-[12.32px]">
            Action
          </Heading>
        ),
        meta: { width: "275px" },
      }),
    ];
  }, []);

  return (
    <>
      <Helmet>
        <title>LearnMigo</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full items-start justify-between gap-5 bg-white-A700 pr-[76px] md:flex-col md:pr-5">
        <Sidebar1 />
        <div className="mt-[22px] flex w-[72%] flex-col items-start gap-[50px] md:w-full">
          <div className="flex items-center gap-8">
            <Text size="6xl" as="p">
              Your Materials
            </Text>
            <a href="https://www.youtube.com/embed/bv8Fxk0sz7I" target="_blank">
              <Button
                color="white_A700"
                size="3xl"
                leftIcon={<Img src="images/img_plus_black_900.svg" alt="plus" className="h-[24px] w-[24px]" />}
                className="mb-1.5 min-w-[182px] gap-[7px] self-end rounded-[15px] tracking-[0.50px] !text-black-900 shadow-xs"
              >
                Upload Materials{" "}
              </Button>
            </a>
          </div>
          <ReactTable
            size="xs"
            bodyProps={{ className: "" }}
            headerProps={{ className: "bg-white-A700 flex-wrap" }}
            rowDataProps={{ className: "md:flex-col" }}
            className="self-stretch border border-solid border-black-900"
            columns={tableColumns}
            data={tableData}
          />
        </div>
      </div>
    </>
  );
}
