// import React from "react";
// import { Img, Text } from "./..";
// import { MenuItem, Menu, Sidebar, sidebarClasses } from "react-pro-sidebar";

// export default function Sidebar4({ ...props }) {
//   const [collapsed, setCollapsed] = React.useState(false);

//   //use this function to collapse/expand the sidebar
//   //function collapseSidebar() {
//   //    setCollapsed(!collapsed)
//   //}

//   return (
//     <Sidebar
//       {...props}
//       width="312px !important"
//       collapsedWidth="80px !important"
//       collapsed={collapsed}
//       className={`${props.className} flex flex-col h-screen pt-[5px] pl-[26px] pr-[25px] top-0 md:p-5 sm:px-5 bg-gray-100 !sticky overflow-auto md:hidden`}
//     >
//       <Img
//         src="images/img_learnmigo_logo_2.png"
//         alt="learnmigologo"
//         className="ml-3 h-[90px] w-[80%] object-cover md:ml-0"
//       />
//       <Text size="md" as="p" className="ml-[25px] mt-[18px] tracking-[1.00px] !text-gray-900_7f md:ml-0">
//         MENU
//       </Text>
//       <Menu
//         menuItemStyles={{
//           button: {
//             padding: "8px 8px 8px 24px",
//             gap: "16px",
//             alignSelf: "start",
//             opacity: 0.6,
//             color: "#273240",
//             fontWeight: 400,
//             fontSize: "12px",
//             [`&:hover, &.ps-active`]: { fontWeight: "500 !important", backgroundColor: "#707fdd !important" },
//           },
//         }}
//         className="mt-[7px] w-full self-stretch"
//       >
//         <div className="flex flex-col gap-[0.24px]">
//           <MenuItem icon={<Img src="images/img_bookmark.svg" alt="bookmark" className="h-[20px] w-[24px]" />}>
//             ClassCopilot
//           </MenuItem>
//           <MenuItem icon={<Img src="images/img_thumbs_up.svg" alt="thumbsup" className="h-[20px] w-[24px]" />}>
//             ProfAI
//           </MenuItem>
//           <MenuItem icon={<Img src="images/img_menu.svg" alt="menu" className="h-[20px] w-[24px]" />}>
//             Your Materials
//           </MenuItem>
//           <MenuItem icon={<Img src="images/img_user.svg" alt="user" className="h-[20px] w-[24px]" />}>
//             Your Classes
//           </MenuItem>
//         </div>
//         <div className="flex flex-col items-start pt-[21px] sm:pt-5">
//           <Text size="md" as="p" className="ml-[25px] tracking-[1.00px] !text-gray-900_7f md:ml-0">
//             OTHERS
//           </Text>
//           <MenuItem icon={<Img src="images/img_info.svg" alt="info" className="h-[20px] w-[24px]" />}>
//             Settings
//           </MenuItem>
//           <MenuItem icon={<Img src="images/img_lock.svg" alt="lock" className="h-[20px] w-[24px]" />}>
//             Your Account
//           </MenuItem>
//           <MenuItem
//             icon={<Img src="images/img_outline_interface_info.svg" alt="outline" className="h-[20px] w-[24px]" />}
//           >
//             Help
//           </MenuItem>
//         </div>
//       </Menu>
//     </Sidebar>
//   );
// }

import React from "react";
import { useNavigate } from "react-router-dom";
import { Img, Text } from "./..";
import { MenuItem, Menu, Sidebar, sidebarClasses } from "react-pro-sidebar";

const basePath = process.env.PUBLIC_URL || "";

export default function Sidebar4({ ...props }) {
  const [collapsed, setCollapsed] = React.useState(false);

  const navigate = useNavigate();

  //use this function to collapse/expand the sidebar
  //function collapseSidebar() {
  //    setCollapsed(!collapsed)
  //}

  return (
    <Sidebar
      {...props}
      width="312px !important"
      collapsedWidth="80px !important"
      collapsed={collapsed}
      className={`${props.className} flex flex-col h-screen pt-[5px] pl-[26px] pr-[25px] top-0 md:p-5 sm:px-5 bg-gray-100 !sticky overflow-auto md:hidden`}
    >
      <Img
        src={`${basePath}/images/img_learnmigo_logo_2.png`}
        alt="learnmigologo"
        className="ml-3 h-[90px] w-[80%] object-cover md:ml-0"
      />
      <Text size="md" as="p" className="ml-[25px] mt-[18px] tracking-[1.00px] !text-gray-900_7f md:ml-0">
        MENU
      </Text>
      <Menu
        menuItemStyles={{
          button: {
            padding: "8px 8px 8px 24px",
            gap: "16px",
            alignSelf: "start",
            opacity: 0.6,
            color: "#273240",
            fontWeight: 400,
            fontSize: "12px",
            [`&:hover, &.ps-active`]: { fontWeight: "500 !important", backgroundColor: "#707fdd !important" },
          },
        }}
        className="mt-[7px] w-full self-stretch"
      >
        <div className="flex flex-col gap-[0.24px]">
          <MenuItem icon={<Img src={`${basePath}/images/img_bookmark.svg`} alt="bookmark" className="h-[20px] w-[24px]"
          onClick={() => navigate("/LessonAIpagemain")}/>}>
            ClassCopilot
          </MenuItem>
          <MenuItem icon={<Img src={`${basePath}/images/img_thumbs_up.svg`} alt="thumbsup" className="h-[20px] w-[24px]" 
          onClick={() => alert("Coming Soon")}/>}>
            ProfAI
          </MenuItem>
          <MenuItem icon={<Img src={`${basePath}/images/img_menu.svg`} alt="menu" className="h-[20px] w-[24px]" 
          onClick={() => alert("Coming Soon")}/>}>
            Your Materials
          </MenuItem>
          <MenuItem icon={<Img src={`${basePath}/images/img_user.svg`} alt="user" className="h-[20px] w-[24px]" 
          onClick={() => alert("Coming Soon")}/>}>
            Your Classes
          </MenuItem>
        </div>
        <div className="flex flex-col items-start pt-[21px] sm:pt-5">
          <Text size="md" as="p" className="ml-[25px] tracking-[1.00px] !text-gray-900_7f md:ml-0">
            OTHERS
          </Text>
          <MenuItem icon={<Img src={`${basePath}/images/img_info.svg`} alt="info" className="h-[20px] w-[24px]" 
          onClick={() => alert("Coming Soon")}/>}>
            Settings
          </MenuItem>
          <MenuItem icon={<Img src={`${basePath}/images/img_lock.svg`} alt="lock" className="h-[20px] w-[24px]" 
          onClick={() => alert("Coming Soon")}/>}>
            Your Account
          </MenuItem>
          <MenuItem
            icon={<Img src={`${basePath}/images/img_outline_interface_info.svg`} alt="outline" className="h-[20px] w-[24px]" 
            onClick={() => alert("Coming Soon")}/>}
          >
            Help
          </MenuItem>
        </div>
      </Menu>
    </Sidebar>
  );
}

